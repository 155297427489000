import i18n from 'i18next';
// import i18next from "i18next";
import { initReactI18next } from 'react-i18next';
import ru from './locales/ru/translation.json';
import en from './locales/en/translation.json';
import es from './locales/es/translation.json';
import it from './locales/it/translation.json';
import de from './locales/de/translation.json';
import nl from './locales/nl/translation.json';
import pl from './locales/pl/translation.json';
import pt from './locales/pt/translation.json';
import tr from './locales/tr/translation.json';
import fr from './locales/fr/translation.json';
import cn from './locales/cn/translation.json';
import jp from './locales/jp/translation.json';

// the translations
const resources = {
  ru: {
    translation: ru
  },
  gb: {
    translation: en
  },
  es: {
    translation: es
  },
  it: {
    translation: it
  },
  de: {
    translation: de
  },
  nl: {
    translation: nl
  },
  pl: {
    translation: pl
  },
  pt: {
    translation: pt
  },
  tr: {
    translation: tr
  },
  fr: {
    translation: fr
  },
  cn: {
    translation: cn
  },
  jp: {
    translation: jp
  }
};

i18n
  .use(initReactI18next)
  .init({
    resources,
    lng: localStorage.getItem('Lang') === null ? 'gb' : localStorage.getItem('Lang').toLowerCase(),
    fallbackLng: 'gb',
    debug: false,
    keySeparator: false,
    interpolation: {
      escapeValue: false,
    }
  });

export default i18n;
