import React, { useState, useEffect } from "react";
import {
  IonSelect,
  IonInput,
  IonLabel,
  IonToast,
  IonItem,
  IonSelectOption
} from "@ionic/react";
import axios from "axios";
import url from "../../../axios.js";
import depositIcon from "../../../images/icons/loan.svg";
import moment from "moment";
import { useTranslation } from "react-i18next";

function Loan(props) {
  const { t } = useTranslation();
  useEffect(() => {
    props.data.balance.forEach(item => {
      if (item.currency_id === props.data.userData.currency_id) {
        switch (item.credit_status) {
          case 0:
            setIsLoan(false);
            setHasLoan(false);
            break;
          case 1:
            setIsLoan(true);
            setMoneyValue(item.credit_value);
            setHasLoan(false);
            break;
          case 2:
            setIsLoan(false);
            setMoneyValue(item.credit_value);
            setDate(item.credit_time);
            setHasLoan(true);
            break;
          default:
          setIsLoan(false);
          setHasLoan(false);
          break;
        }
      }
    });
  }, [props.data.balance]);
  const [showToast1, setShowToast1] = useState(false);
  const [showToast2, setShowToast2] = useState(false);
  const [isLoan, setIsLoan] = useState(false);
  const [date, setDate] = useState("");
  const [hasLoan, setHasLoan] = useState(false);
  const [showToast2Text, setShowToast2Text] = useState(false);
  const [currency, setCurrency] = useState();
  const [moneyValue, setMoneyValue] = useState();
  const [payoff, setPayoff] = useState();
  const [maturity, setMaturity] = useState("");
  function payOff() {
    if (moneyValue === undefined || moneyValue === "") {
      setMoneyValue("");
    }
    if (moneyValue !== undefined && moneyValue !== "") {
      props.setShowLoading(true);
      props.setShowLoading(true);
      axios({
        method: "post",
        url: url + "/api/pay-credit",
        headers: {
          Authorization: `Bearer ${props.data.token}`
        },
        data: {
          value: payoff
        }
      })
        .then(res => {
          props.updateUser(props.data.token);
          props.setShowLoading(false);
        })
        .catch(error => {
          /*422*/
          props.setShowLoading(false);
          setShowToast2Text(error.response.data.error);
          setShowToast2(true);
        });
    }
  }
  function deposit() {
    if (moneyValue === undefined || moneyValue === "") {
      setMoneyValue("");
    }
    if (moneyValue !== undefined && moneyValue !== "" && maturity !== "") {
      props.setShowLoading(true);
      axios({
        method: "post",
        url: url + "/api/get-credit",
        headers: {
          Authorization: `Bearer ${props.data.token}`
        },
        data: {
          value: Math.ceil(moneyValue),
          time: maturity
        }
      })
        .then(res => {
          props.updateUser(props.data.token);
          setShowToast1(true);
          setIsLoan(true);
          setMoneyValue(null);
          props.setShowLoading(false);
        })
        .catch(error => {
          /*422*/
          props.setShowLoading(false);
          setShowToast2Text(error.response.data.error);
          setShowToast2(true);
        });
      // props.setShowLoading(false)
    }
  }
  function getCurrency(currency_id) {
    if (currency_id === 840) {
      return "$";
    }
    if (currency_id === 978) {
      return "€";
    }
    if (currency_id === 643) {
      return "₽";
    }
    if (currency_id === 826) {
      return "£";
    }
  }
  function withNulls(e) {
    setMoneyValue(e.detail.value);
  }
  function withNullsPay(e) {
    setPayoff(e.detail.value);
  }
  return (
    <div className="deposit-money">
      <div className="shade">
        <p className="top-shade">
          <img alt="deposit" src={depositIcon} className="deposit-icon" />
        </p>
      </div>
      <p className="title">
        <span>{t("Loan request")}</span>
      </p>
      {isLoan === false && hasLoan === false && (
        <div className="account-details-container closure">
          <div className="step-input-container">
            <p className="step-input-title">{t("Value")}</p>
            <IonInput
              className={
                "money-value header-search " +
                (moneyValue !== undefined && moneyValue === "" ? "wrong" : "")
              }
              type="text"
              value={moneyValue}
              placeholder={t("Value")}
              onIonChange={e => withNulls(e)}
              onIonBlur={() =>
                setMoneyValue(
                  (+moneyValue).toFixed(2) === "NaN"
                    ? "0.00"
                    : (+moneyValue).toFixed(2)
                )
              }
            />
          </div>
          <div className="step-input-container">
            <p className="step-input-title">{t("Loan maturity")}</p>
            <IonSelect
              onIonChange={e => setMaturity(e.detail.value)}
              value={maturity}
              className={
                "header-search country cur " +
                (moneyValue !== undefined && currency === "" ? "wrong" : "")
              }
              interface="popover"
            >
              <IonSelectOption value={"day"}>{t("Day")}</IonSelectOption>
              <IonSelectOption value={"3 days"}>{t("3 days")}</IonSelectOption>
              <IonSelectOption value={"week"}>{t("Week")}</IonSelectOption>
              <IonSelectOption value={"month"}>{t("Month")}</IonSelectOption>
            </IonSelect>
          </div>
          <div className="step-input-container">
            <IonItem
              button
              onClick={() => deposit()}
              className="register-btn submit depst"
            >
              <IonLabel>{t("Submit")}</IonLabel>
            </IonItem>
          </div>
        </div>
      )}
      {isLoan === true && (
        <div className="account-details-container closure">
          <p className="transfer-from">
            {t("Your")} {moneyValue}
            {getCurrency(props.data.userData.currency_id)} {t("loan is pending")}.
          </p>
        </div>
      )}
      {hasLoan === true && (
        <div className="account-details-container closure">
          <p className="deposit">
            {t("You have")}{" "}
            <b>
              {moneyValue} {getCurrency(props.data.userData.currency_id)}
            </b>{" "}
            {t("loan")}.
          </p>
          <p className="deposit">
            {t("You need to repay the loan before")}{" "}
            <b>{moment.unix(date).format("MM/DD/YYYY, h:mm")}</b>
          </p>
          <p className="deposit">
            {t("You can pay off your debt in whole or in parts.")}
          </p>
          <div className="step-input-container">
            <p className="step-input-title deposit">{t("Value")}</p>
            <IonInput
              className={
                "money-value header-search " +
                (moneyValue !== undefined && moneyValue === "" ? "wrong" : "")
              }
              type="text"
              value={payoff}
              placeholder={t("Value")}
              onIonChange={e => withNullsPay(e)}
              onIonBlur={() =>
                setPayoff(
                  (+payoff).toFixed(2) === "NaN" ? "0.00" : (+payoff).toFixed(2)
                )
              }
            />
          </div>
          <div className="step-input-container">
            <IonItem
              button
              onClick={() => payOff()}
              className="register-btn submit depst"
            >
              <IonLabel>{t("Pay off")}</IonLabel>
            </IonItem>
          </div>
        </div>
      )}
      <IonToast
        isOpen={showToast1}
        onDidDismiss={() => setShowToast1(false)}
        message={t("Your loan application is pending.")}
        color="success"
        duration={5000}
      />
      <IonToast
        isOpen={showToast2}
        onDidDismiss={() => setShowToast2(false)}
        message={showToast2Text}
        color="danger"
        duration={5000}
      />
    </div>
  );
}

export default Loan;
