import React from "react";
import { IonItem, IonLabel } from "@ionic/react";
import DataChart from "../../../components/DataChart.js";
import TablePart from "../../../components/TablePart.js";
import DayPickerInput from "react-day-picker/DayPickerInput";
import MomentLocaleUtils from "react-day-picker/moment";
import "../../../css/Daypicker.css";
import moment from "moment";
import { Card, CardBody } from "shards-react";
import axios from "axios";
import url from "../../../axios.js";
import i18next from "i18next";
import { formatDate, parseDate } from "react-day-picker/moment";
import casinoIcon from "../../../images/icons/casino.svg";

export default class CasinoHistory extends React.Component {
  constructor(props) {
    super(props);
    this.handleFromChange = this.handleFromChange.bind(this);
    this.handleToChange = this.handleToChange.bind(this);
    this.state = {
      from: new Date(moment().subtract(7, "days")),
      to: new Date(),
      transactions: [],
      totalLength: [],
      value1: 0,
      value2: 0,
      amount: 0,
      showFrom: 0,
      count: 0
    };
  }
  componentDidMount() {
    if (this.props.data.token !== null) {
      this.props.setShowLoading(true);
      axios({
        method: "post",
        url: url + "/api/history-bets",
        headers: {
          Authorization: `Bearer ${this.props.data.token}`
        },
        data: {
          date_from: moment()
            .subtract(7, "days")
            .unix(),
          date_to: moment()
            .add(7, "days")
            .unix()
        }
      }).then(res => {
        this.props.setShowLoading(false);
        let amount = 0;
        let bets = 0;
        let wins = 0;
        res.data.data.history.forEach((item, i) => {
          amount += +item.amount;
          bets += +item.amount_bet;
          if (item.amount_win !== null) {
            wins += +item.amount_win;
          }
        });
        this.setState({
          transactions: res.data.data.history,
          value1: bets,
          value2: wins,
          amount: amount,
          count: res.data.data.history.length
        });
        let start = 1;
        let result = [];
        while (start <= Math.ceil(this.state.transactions.length / 10)) {
          result.push(start++);
        }
        this.setState({ totalLength: result });
      });
    }
  }
  show() {
    if (this.state.from !== undefined && this.state.to !== undefined) {
      this.props.setShowLoading(true);
      axios({
        method: "post",
        url: url + "/api/history-bets",
        headers: {
          Authorization: `Bearer ${this.props.data.token}`
        },
        data: {
          date_from: moment(this.state.from).unix(),
          date_to: moment(this.state.to).unix()
        }
      }).then(res => {

        this.props.setShowLoading(false);
        let amount = 0;
        let bets = 0;
        let wins = 0;
        res.data.data.history.forEach((item, i) => {
          amount += +item.amount;
          bets += +item.amount_bet;
          if (item.amount_win !== null) {
            wins += +item.amount_win;
          }
        });

        this.setState({
          transactions: res.data.data.history,
          value1: bets,
          value2: wins,
          amount: amount,
          count: res.data.data.history.length
        });
        let start = 1;
        let result = [];
        while (start <= Math.ceil(this.state.transactions.length / 10)) {
          result.push(start++);
        }
        this.setState({ totalLength: result });
      });
    }
  }

  showFromMonth() {
    const { from, to } = this.state;
    if (!from) {
      return;
    }
    if (moment(to).diff(moment(from), "months") < 2) {
      this.to.getDayPicker().showMonth(from);
    }
  }

  handleFromChange(from) {
    this.setState(state => {
      return { from };
    });
  }

  handleToChange(to) {
    this.setState({ to }, this.showFromMonth);
  }
  paginate(item) {
    this.setState({ showFrom: item });
  }
  render() {
    function pickCurrency(currency_id) {
      if (currency_id === 840) {
        return "$";
      }
      if (currency_id === 978) {
        return "€";
      }
      if (currency_id === 643) {
        return "₽";
      }
      if (currency_id === 826) {
        return "£";
      }
    }
    const { from, to } = this.state;
    const modifiers = { start: from, end: to };
    const modifiersStyles = {
      birthday: {
        color: "white",
        backgroundColor: "#ffc107"
      },
      thursdays: {
        color: "#ffc107",
        backgroundColor: "#fffdee"
      }
    };
    return (
      <div className="transactions">
        <div className="shade">
          <p className="top-shade">
            <img alt="casino" src={casinoIcon} />
          </p>
        </div>
        <div className="transactions-container">
          <p className="title">
            <span>{i18next.t('Bets history')}</span>
          </p>
          <div className="time-buttons">
            <IonItem
              button
              onClick={() => {
                let promise = new Promise(resolve => {
                  this.paginate(0);
                  this.handleFromChange(new Date(moment().subtract(1, "days")));
                  resolve(this.state.from);
                });
                promise.then(result => {
                  this.show();
                });
              }}
              className="register-btn"
            >
              <IonLabel>{i18next.t('Day')}</IonLabel>
            </IonItem>
            <IonItem
              button
              onClick={() => {
                let promise = new Promise(resolve => {
                  this.paginate(0);
                  this.handleFromChange(new Date(moment().subtract(7, "days")));
                  resolve(this.state.from);
                });
                promise.then(result => {
                  this.show();
                });
              }}
              className="register-btn"
            >
              <IonLabel>{i18next.t('Week')}</IonLabel>
            </IonItem>
            <IonItem
              button
              onClick={() => {
                let promise = new Promise(resolve => {
                  this.paginate(0);
                  this.handleFromChange(
                    new Date(moment().subtract(1, "months"))
                  );
                  resolve(this.state.from);
                });
                promise.then(result => {
                  this.show();
                });
              }}
              className="register-btn"
            >
              <IonLabel>{i18next.t('Month')}</IonLabel>
            </IonItem>
            <IonItem
              button
              onClick={() => {
                let promise = new Promise(resolve => {
                  this.paginate(0);
                  this.handleFromChange(new Date(moment().subtract(1, "year")));
                  resolve(this.state.from);
                });
                promise.then(result => {
                  this.show();
                });
              }}
              className="register-btn"
            >
              <IonLabel>{i18next.t('Year')}</IonLabel>
            </IonItem>
            <IonItem
              button
              onClick={() => {
                let promise = new Promise(resolve => {
                  this.paginate(0);
                  this.handleFromChange(
                    new Date(moment().subtract(3, "months"))
                  );
                  resolve(this.state.from);
                });
                promise.then(result => {
                  this.show();
                });
              }}
              className="register-btn"
            >
              <IonLabel>{i18next.t('Quarter')}</IonLabel>
            </IonItem>
            <IonItem
              button
              onClick={() => {
                let promise = new Promise(resolve => {
                  this.paginate(0);
                  this.handleFromChange(new Date("2020-01-01 17:13:37"));
                  resolve(this.state.from);
                });
                promise.then(result => {
                  this.show();
                });
              }}
              className="register-btn"
            >
              <IonLabel>{i18next.t('All time')}</IonLabel>
            </IonItem>
          </div>
          <div className="InputFromTo">
            <DayPickerInput
              value={from}
              placeholder={i18next.t("From")}
              format="LL"
              localeUtils= {MomentLocaleUtils}
              locale="ru"
              modifiersStyles={modifiersStyles}
              formatDate={formatDate}
              parseDate={parseDate}
              dayPickerProps={{
                selectedDays: [from, { from, to }],
                disabledDays: { after: to },
                toMonth: to,
                modifiers,
                numberOfMonths: 2,
                onDayClick: () => this.to.getInput().focus()
              }}
              onDayChange={this.handleFromChange}
            />
            <span className="InputFromTo-to">
              <DayPickerInput
                ref={el => (this.to = el)}
                value={to}
                placeholder={i18next.t("To")}
                format="LL"
                modifiersStyles={modifiersStyles}
                formatDate={formatDate}
                parseDate={parseDate}
                dayPickerProps={{
                  selectedDays: [from, { from, to }],
                  disabledDays: { before: from },
                  modifiers,
                  month: from,
                  fromMonth: from,
                  numberOfMonths: 2
                }}
                onDayChange={this.handleToChange}
              />
            </span>
          </div>
          <IonItem
            button
            onClick={() => {this.paginate(0);this.show()}}
            className="register-btn submit"
          >
            <IonLabel>{i18next.t("Show")}</IonLabel>
          </IonItem>

          {/*card*/}
          <DataChart
            transactions={[]}
            data={this.props.data}
            setShowLoading={this.props.setShowLoading}
            amount={this.state.amount}
            label1={"bet"}
            label2={"wins"}
            value1={this.state.value1}
            value2={this.state.value2}
          />
          {this.state.transactions.length === 0 && (
            <IonItem className="no-transactions">
              <IonLabel>{i18next.t("No games history during this period.")}</IonLabel>
            </IonItem>
          )}
          {this.state.transactions.length > 0 && (
            <Card className="card-table">
              <CardBody className="p-0 pb-3">
                <table className="table mb-0">
                  <thead className="card-header-main">
                    <tr>
                      <th scope="col" className="border-0">
                      {i18next.t("Date & Time")}
                      </th>
                      <th scope="col" className="border-0">
                        {i18next.t("Bet")}
                      </th>
                      <th scope="col" className="border-0">
                        {i18next.t("Win")}
                      </th>
                      <th scope="col" className="border-0">
                        {i18next.t("Amount")}
                      </th>

                    </tr>
                  </thead>

                    {
                      this.state.transactions.map((el, i) => {
                      if (
                        i >= this.state.showFrom &&
                        i < this.state.showFrom + 10
                      ) {
                        return (
                          <TablePart key={i} i={i} el={el}/>
                        );
                      }
                      return null;
                    })
                  }

                </table>
              </CardBody>
              {this.state.transactions.length > 10 && (
                <div className="card-paginator">
                  <div onClick={() => this.paginate(0)} className="arrow">
                    {"<"}
                  </div>
                  <div className="paginator-container">
                    {this.state.totalLength.map((el, i) => {
                      return (
                        <div onClick={() => this.paginate(i * 10)} key={i}>
                          {i + 1}
                        </div>
                      );
                    })}
                  </div>
                  <div
                    onClick={() =>
                      this.paginate(
                        (this.state.count -
                          (this.state.count -
                            (this.state.totalLength.length - 1))) *
                          10
                      )
                    }
                    className="arrow"
                  >
                    {">"}
                  </div>
                </div>
              )}
            </Card>
          )}
          {/*card-end*/}
        </div>
      </div>
    );
  }
}
