import React, { useState, useEffect } from "react";
import {
  IonToast,
  IonInput,
  IonLabel,
  IonItem,
  IonSelectOption,IonSelect
} from "@ionic/react";
import axios from "axios";
import url from "../../../axios.js";
import { useTranslation } from "react-i18next";
import withdrawIcon from "../../../images/icons/withdraw.svg";

function WithdrawMoney(props) {
  const { t } = useTranslation();
  const [showToast1, setShowToast1] = useState(false);
  const [showToast2, setShowToast2] = useState(false);
  const [showToast3, setShowToast3] = useState(false);
  const [withdrawAddress, setWithdrawAddress] = useState('');
  const [showToast2Text, setShowToast2Text] = useState(false);
  const [showToast3Text, setShowToast3Text] = useState('');
  const [currency, setCurrency] = useState(978);
  const [btcPrice, setBtcPrice] = useState(0);
  const [moneyValue, setMoneyValue] = useState('');
  useEffect(()=> {
    if (props.data.listPaymentSystem.some(e => e.payment_system_id === currency)) {
      axios({
        method: 'post',
        url: url + "/api/get-curs-crypto",
        data: {
          payment_system_id: currency,
          currency_entity: 'EUR'
        },
        headers: {
          'Authorization': `Bearer ${props.data.token}`,
        },
      }).then(res => {
          setBtcPrice(res.data.curs.price);
        })
    };
  },[currency]);
  function getCurrency(currency_id) {
    if (currency_id === 840) {
      return '$'
    }
    if (currency_id === 978) {
      return '€'
    }
    if (currency_id === 643) {
      return '₽'
    }
    if (currency_id === 826) {
      return '£'
    }
    if (currency_id === 'bitcoin') {
      return 'BTC'
    }
    if (currency_id === 'litecoin') {
      return 'LTC'
    }
    if (currency_id === "USDT") {
      return "USDT"
    }
    else {
      return currency_id
    }
  }
  let withdraw = () => {
    if (!props.data.listPaymentSystem.some(e => e.payment_system_id === currency)) {
      if (currency === undefined || currency === "") {
        setCurrency("");
      }
      if (moneyValue === undefined || moneyValue === "") {
        setMoneyValue("");
      }
      if (moneyValue !== undefined && moneyValue !== "") {
        props.setShowLoading(true);
        axios({
          method: "post",
          url: url + "/api/withdraw-money",
          headers: {
            Authorization: `Bearer ${props.data.token}`
          },
          data: {
            currency: 978,
            amount: moneyValue
          }
        })
          .then(res => {
            props.updateUser(props.data.token);
            setShowToast1(true);
            setMoneyValue(null);
            props.setShowLoading(false);
          })
          .catch(error => {
            /*422*/
            props.setShowLoading(false);
            setShowToast2Text(error.response.data.error);
            setShowToast2(true);
          });
      }
    }
    if (props.data.listPaymentSystem.some(e => e.payment_system_id === currency)) {
      axios({
      method: 'post',
      url: url + "/api/request-out-crypto",
      headers: {
        'Authorization': `Bearer ${props.data.token}`,
      },
      data: {
        payment_system_id: currency,
        currency_entity: 'EUR',
        summa_out: moneyValue,
        address_out: withdrawAddress,
        iscrypto: 1
      }
    })
    .then(res => {
      if (!res.data.error) {
        props.updateUser(props.data.token);
        setShowToast3(true);
        setShowToast3Text("Your withdrawal has been submitted.");
      }
      else {
        setShowToast2Text("Your amount is less than 20 EUR.");
        setShowToast2(true);
      }
      props.setShowLoading(false);
    })
    .catch(error => {
      /*422*/
      if (error.response.data.status === 422) {
      props.setShowLoading(false);
      setShowToast2Text(error.response.data.error);
      setShowToast2(true);
    }
    });
    }

  };

  return (
    <div className="withdraw-money">
      <div className="shade">
        <p className="top-shade">
          <img alt="withdraw" src={withdrawIcon} />
        </p>
      </div>
      <p className="title">
        <span>{t("Withdraw money")}</span>
      </p>
      <div className="account-details-container closure">
        <div className="step-input-container">
          <p className="step-input-title">{t("Currency")}</p>
        <IonSelect
          onIonChange={e => {setCurrency(e.detail.value)}}
          placeholder={t("Currency")}
          value={currency}
          className={
            "header-search gender currency"
          }
          interface="popover"
        >
        {
          props.data.listPaymentSystem.map((el,i)=> {
            return <IonSelectOption key={i} value={el.payment_system_id}>{el.name}</IonSelectOption>
          })
        }
        {
            props.data.currencies.map((el, i) => {
            return (
              <IonSelectOption key={i} value={el.id}>
                {el.full_name}
              </IonSelectOption>
            );
          })
        }
        </IonSelect>
      </div>
        <div className="step-input-container">
          <p className="step-input-title">{t("Value")}, €</p>
          <IonInput
            className={
              "money-value header-search " +
              (moneyValue !== undefined && moneyValue === "" ? "wrong" : "")
            }
            type="text"
            value={moneyValue}
            placeholder={t("Value")}
            onIonChange={e => setMoneyValue(e.detail.value)}
          />
        </div>
        {props.data.listPaymentSystem.some(e => e.payment_system_id === currency) && <div className="step-input-container">
          <p className="step-input-title">{t("Wallet")}</p>
          <IonInput
            className={
              "money-value header-search " +
              (moneyValue !== undefined && moneyValue === "" ? "wrong" : "")
            }
            type="text"
            value={withdrawAddress}
            placeholder={t("Wallet")}
            onIonChange={e => setWithdrawAddress(e.detail.value)}
          />
        </div>}
        <div className="step-input-container">
          <IonItem
            button
            onClick={() => withdraw()}
            className="register-btn submit"
          >
            <IonLabel>{t("Withdraw")}</IonLabel>
          </IonItem>
        </div>
      </div>
      <IonToast
        isOpen={showToast1}
        onDidDismiss={() => setShowToast1(false)}
        message={"Your withdrawal has been submitted."}
        color="success"
        duration={5000}
      />
      <IonToast
        isOpen={showToast3}
        onDidDismiss={() => setShowToast2(false)}
        message={showToast3Text}
        color="danger"
        duration={5000}
      />
      <IonToast
        isOpen={showToast2}
        onDidDismiss={() => setShowToast2(false)}
        message={showToast2Text}
        color="danger"
        duration={5000}
      />
    </div>
  );
}

export default WithdrawMoney;
