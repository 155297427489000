import React, { useState, useEffect } from "react";
import balanceIcon from "../../../images/icons/balance.svg";
import { useTranslation } from "react-i18next";
export default function Balance(props) {
  const [balanceTotal, setBalanceTotal] = useState(0);
  const [bonusTotal, setBonusTotal] = useState(0);
  const [credit, setCredit] = useState('');
  const { t } = useTranslation();
  // const [userCurrency, setUserCurrency] = useState(props.data.userData.currency_id);
  useEffect(() => {
    let balance = 0;
    let bonus = 0;
    if (props.data.balance.length > 0) {
      props.data.balance.forEach((item, i) => {
        balance += +item.ob_priv;
        bonus += +item.ob_priv_b;
        if (item.currency_id === props.data.userData.currency_id) {
          if (item.credit_status === 2) {
            setCredit(item.credit_value);
          }
        }
      });
      setBalanceTotal(balance.toFixed(2));
      setBonusTotal(bonus.toFixed(2));
    }
  }, [props.data.balance]);

  function getCurrency(currency_id) {
    if (currency_id === 840) {
      return "$";
    }
    if (currency_id === 978) {
      return "€";
    }
    if (currency_id === 643) {
      return "₽";
    }
    if (currency_id === 826) {
      return "£";
    }
  }
  return (
    <div className="transfer-money">
      <div className="shade">
        <p className="top-shade">
          <img alt="balance" src={balanceIcon} className="balance-icon" />
        </p>
        <p className="bottom-shade" />
      </div>
      <p className="title">
        <span>{t('Balance')}</span>
      </p>

      {
        //   props.data.balance.map((el, i) => {
        //   return (
        //     <p key={i} className="deposit">
        //       Deposit in {getCurrency(el.currency_id)}{" "}
        //       <span>
        //         {el.ob}
        //         {pickCurrency(el.currency_id)}
        //       </span>
        //     </p>
        //   );
        // })
      }
      {credit !== '' && <p className="deposit">
        {t('Loan')}{" "}
        <span>
          {credit}
          {getCurrency(props.data.userData.currency_id)}
        </span>
      </p>}
      <p className="deposit">
        {t('Total balance')}{" "}
        <span>
          {balanceTotal}
          {getCurrency(props.data.userData.currency_id)}
        </span>
      </p>
      <p className="deposit">
        {t('Total bonuses')}{" "}
        <span>
          {bonusTotal}
          {getCurrency(props.data.userData.currency_id)}
        </span>
      </p>
      <p className="deposit">
        {t('Cashback')} <span>0{getCurrency(props.data.userData.currency_id)}</span>
      </p>
      <div className="account-details-container closure" />
    </div>
  );
}
