import React,{useState} from "react";
import Frame from "../components/Frame.js";
import Header from "../components/Header.js";
import Footer from "../components/Footer.js";
import { Link } from "react-router-dom";

import { IonContent, IonPage,IonModal } from "@ionic/react";
import "../css/main.css";
import { useLocation } from 'react-router-dom';
import { useTranslation } from "react-i18next";
const Home = props => {
  const { t } = useTranslation();


  const [showCookiesModal, setShowCookiesModal] = useState(true);
  const [fullscreen, setFullScreen] = useState(false);
  let location = useLocation();
  function acceptCookies() {
    setShowCookiesModal(false);
    localStorage.setItem('cookies', 'accepted');
  }
  // скролл к элементу
  // function goGownUp(boo) {
  //   setDownBtn(!downBtn);
  //   if (downBtn === true) {
  //     scrollToBottom();
  //   } else {
  //     scrollToTop();
  //   }
  // }
  // function scrollToBottom() {
  //     document.querySelector('.scroll-me').scrollToBottom(500);
  //   }
  //   function scrollToTop() {
  //     document.querySelector('.scroll-me').scrollToTop(500);
  //   }
  function checkScroll(e) {
    if (e.detail.scrollTop > 50 && e.detail.scrollTop < 600 && fullscreen !== true) {
      // убираем поисковую строку браузера
      setFullScreen(true);

      // let height = document.getElementsByTagName('html')[0].clientHeight;
      // document.getElementsByTagName('html')[0].style.height = `${height + "1px"}`;
      window.scrollTo(0, 3);
    }
    if (e.detail.scrollTop === 0) {
      // возвращаем поисковую строку браузера
      setFullScreen(false);
      window.scrollTo(0, 0);
    }
  }
  return (
    <IonPage>
      <IonContent className={'scroll-me'} scrollEvents={true} onIonScroll={e => checkScroll(e)} fullscreen>
        <Header
          theme={props.theme}
          setTheme={props.setTheme}
          data={props.data}
          setShowLoading={props.setShowLoading}
          setToken={props.setToken}
        />

      <Frame token={props.data.token} htmlString={props.htmlString} />
      {location.pathname==='/home' && <Footer />}
      {
        props.cookies !== 'accepted' && location.pathname === "/home" &&
          <IonModal
            backdropDismiss={false}
            isOpen={showCookiesModal}
            cssClass='cookies-modal'
            >
            <div className="cookies-container">
              <p>{t("By entering the site, you accept our")} <Link to='/documents/data-protection'>{t("Data Protection Policy")}</Link>, <Link to='/documents/cookie-policy'>{t("Cookie Policy")}</Link> {t("and")} <Link to='/documents/disclaimer'>{t("Disclaimer")}</Link> {t("and confirm you are at least 18 years of age.")}</p>
              <div onClick={() => acceptCookies()} className="accept-cookies">{t("Accept")}</div>
            </div>
          </IonModal>
      }
      </IonContent>
    </IonPage>
  );
};

export default Home;
