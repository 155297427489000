import React, { useState, useEffect } from "react";
import {
  IonSelect,
  IonLabel,
  IonItem,
  IonToast,
  IonSelectOption,
  IonInput,
  IonModal
} from "@ionic/react";
import ReactFlagsSelect from "react-flags-select";
import "react-flags-select/css/react-flags-select.css";
import url from "../../../axios.js";
import axios from "axios";
import moment from "moment";
import { useDropzone } from "react-dropzone";
import userIcon from "../../../images/icons/user.svg";
import { useTranslation } from "react-i18next";

function Previews(props) {
  const [files, setFiles] = useState([]);
  const { t } = useTranslation();
  const { getRootProps, getInputProps, isDragActive } = useDropzone({
    accept: "image/*",
    onDrop: acceptedFiles => {
      props.getFiles(acceptedFiles);
      setFiles(
        acceptedFiles.map(file =>
          Object.assign(file, {
            preview: URL.createObjectURL(file)
          })
        )
      );
    }
  });
  const style = {
    position: "absolute",
    width: "100%",
    height: "100%",
    borderRadius: "50%",
    border: "1px solid #707070"
  };
  const thumbs = files.map(file => (
    <div className={"thumb"} key={file.name}>
      <div
        alt="file"
        style={{ backgroundImage: `url(${file.preview})` }}
        className={"previewImg avatar-prev"}
      />
    </div>
  ));

  useEffect(
    () => () => {
      // Make sure to revoke the data uris to avoid memory leaks
      files.forEach(file => URL.revokeObjectURL(file.preview));
    },
    [files]
  );
  return (
    <section className="file-zone-container userpic userpic-container">
      <div {...getRootProps({ className: "dropzone" })}>
        <input {...getInputProps()} />
        {isDragActive && files !== [] ? (
          <div className={"file-zone-active"}>
            <div className={"file-zone-img"} />
            {t('Drop file here')}
          </div>
        ) : (
          <div className={"file-zone"}>
            <div
              alt="file"
              style={{ background: `url(${props.pic})` }}
              src={props.pic}
              className={"previewImg avatar-prev"}
            />
          </div>
        )}
        {files !== [] && (
          <div style={style} className={"thumbs-container"}>
            {thumbs}
          </div>
        )}
      </div>
    </section>
  );
}

function PersonalDetails(props) {
  const { t } = useTranslation();
  const [passwordChangedToast, setPasswordChangedToast] = useState(false);
  const [showToast1, setShowToast1] = useState(false);
  const [showToast2, setShowToast2] = useState(false);
  const [showToast3, setShowToast3] = useState(false);
  const [showToast2Text, setShowToast2Text] = useState(false);
  const [permittedCountries, setPermittedCountries] = useState(
    props.data.countries
  );
  const [firstName, setFirstName] = useState(props.data.userData.first_name);
  const [avatar, setAvatar] = useState(props.data.userData.avatar);
  const [lastName, setLastName] = useState(props.data.userData.last_name);
  const [gender, setGender] = useState(props.data.userData.title);
  const [day, setDay] = useState(
    moment.unix(props.data.userData.birth_date).format("D")
  );
  const [year, setYear] = useState(
    moment.unix(props.data.userData.birth_date).format("YYYY")
  );
  const [language, setLanguage] = useState(props.data.userData.language);
  const [month, setMonth] = useState(
    moment.unix(props.data.userData.birth_date).format("M")
  );
  const [country, setCountry] = useState(props.data.userData.country);
  const [emailInUse, setEmailInUse] = useState(false);
  const [emailFailure, setEmailFailure] = useState(
    t('This email is already in use')
  );
  const [currency, setCurrency] = useState(props.data.userData.currency_id);
  const [email, setEmail] = useState(props.data.userData.email);
  const [changePasswordModal, setChangePasswordModal] = useState(false);
  const yearFor = new Date().getFullYear();
  const years = Array.from(new Array(80), (val, index) => yearFor - index);
  const dates = Array.from(new Array(31), (val, index) => ++index);
  const [newPassword, setNewPassword] = useState();
  const [newPasswordConfirm, setNewPasswordConfirm] = useState();
  const [confirmation, setConfirmation] = useState();
  useEffect(() => {
    setPermittedCountries(props.data.countries);
  }, [props.data.countries]);
  var months = [
    t("January"),
    t("February"),
    t("March"),
    t("April"),
    t("May"),
    t("June"),
    t("July"),
    t("August"),
    t("September"),
    t("October"),
    t("November"),
    t("December")
  ];
  function save() {
    if (gender === undefined || gender === "") {
      setGender("");
    }
    if (firstName === undefined || firstName === "") {
      setFirstName("");
    }
    if (lastName === undefined || lastName === "") {
      setLastName("");
    }
    if (day === undefined || day === "") {
      setDay("");
    }
    if (year === undefined || year === "") {
      setYear("");
    }
    if (month === undefined || month === "") {
      setMonth("");
    }
    if (email === undefined || email === "") {
      setEmail("");
    }
    if (country === undefined || country === "") {
      setCountry("");
    }
    if (currency === undefined || currency === "") {
      setCurrency("");
    }
    if (language === undefined || language === "") {
      setLanguage("");
    }
    if (
      gender !== undefined &&
      gender !== "" &&
      firstName !== undefined &&
      firstName !== "" &&
      lastName !== undefined &&
      lastName !== "" &&
      day !== undefined &&
      day !== "" &&
      year !== undefined &&
      year !== "" &&
      month !== undefined &&
      month !== "" &&
      country !== undefined &&
      country !== "" &&
      email !== undefined &&
      email !== "" &&
      currency !== undefined &&
      currency !== "" &&
      language !== undefined &&
      language !== ""
    ) {
      props.setShowLoading(true);
      //   function currentEmail(){
      //   if (email !== props.data.userData.email) {
      //     return `email: ${email}`
      //   } else {
      //     currentEmail =''
      //   }
      // }
      var data = {
        title: gender,
        first_name: firstName,
        last_name: lastName,
        birth_date: moment(
          moment(parseInt(month) + "/" + day + "/" + year).format("L")
        ).unix(),
        country: country,
        language: language,
        currency_id: currency
      };
      email !== props.data.userData.email
        ? (data.email = email)
        : console.log("email not changed");
      avatar !== props.data.userData.avatar
        ? (data.avatar = avatar)
        : console.log("avatar not changed");
      axios({
        method: "post",
        url: url + "/api/update-profile",
        headers: {
          Authorization: `Bearer ${props.data.token}`
        },
        data: data
      })
        .then(res => {
          if (res.data.status === 1) {
            props.updateUser(props.data.token);
            setShowToast1(true);
            props.setShowLoading(false);
          }
        })
        .catch(error => {
          /*422*/
          props.setShowLoading(false);
          setShowToast2Text(error.response.data);
          setShowToast2(true);
        });
    }
  }
  function changePassword() {
    axios({
      method: "post",
      url: url + "/api/restore-password",
      data: {
        restore: email
      }
    }).then(res => {
      setChangePasswordModal(true);
      setShowToast3(true);
    });
  }
  function getFiles(file) {
    function getBase64(file) {
      var reader = new FileReader();
      reader.readAsDataURL(file);
      reader.onload = function() {
        setAvatar(reader.result);
      };
      reader.onerror = function(error) {
        console.log("Error: ", error);
      };
    }
    getBase64(file[0]);
  }
  function checkEmail() {
    if (email !== undefined && email !== "") {
      axios({
        method: "post",
        url: url + "/api/checkemail",
        data: {
          email: email
        }
      })
        .then(res => {
          setEmailInUse(false);
        })
        .catch(error => {
          /*422*/
          console.log(error.response.data.error[0]);
          setEmailFailure(error.response.data.error[0]);
          setEmailInUse(true);
        });
    }
  }
  function restoreConfirm() {
    if (
      confirmation !== undefined &&
      newPassword !== undefined &&
      newPasswordConfirm !== undefined &&
      newPassword !== "" &&
      confirmation !== "" &&
      newPasswordConfirm !== "" &&
      newPasswordConfirm === newPassword
    ) {
      props.setShowLoading(true);
      axios({
        method: "post",
        url: url + "/api/restore-confirm",
        data: {
          password: newPassword,
          confirmation: confirmation,
          restore: email
        }
      }).then(res => {
        // console.log(res);
        // setShowToast(true);
        setPasswordChangedToast(true);
        setChangePasswordModal(false);
        props.setShowLoading(false);
      });
    }
  }
  return (
    <div className="deposit-money">
      <div className="shade">
        <p className="top-shade">
          <img alt="user" src={userIcon} />
        </p>
      </div>
      <p className="title">
        <span>{t("Account Details")}</span>
      </p>
      <p className="title-2">{t("Personal details")}</p>
      <div className="account-details-container">
        <div className="step-input-container">
          <p className="step-input-title">{t('Title')}</p>
          <IonSelect
            onIonChange={e => setGender(e.detail.value)}
            placeholder={t("Mr.")}
            value={gender}
            className={
              "header-search gender " +
              (gender !== undefined && gender === "" ? "wrong" : "")
            }
            interface="popover"
          >
            <IonSelectOption value="mr">{t("Mr.")}</IonSelectOption>
            <IonSelectOption value="ms">{t("Ms.")}</IonSelectOption>
          </IonSelect>
        </div>
        <div className="step-input-container">
          <p className="step-input-title">{t("First Name")}</p>
          <IonInput
            className={
              "header-search " +
              (firstName !== undefined && firstName === "" ? "wrong" : "")
            }
            type="text"
            value={firstName}
            placeholder={t("First Name")}
            onIonChange={e => setFirstName(e.detail.value)}
          />
        </div>
        <div className="step-input-container">
          <p className="step-input-title">{t("Last Name")}</p>
          <IonInput
            className={
              "header-search " +
              (lastName !== undefined && lastName === "" ? "wrong" : "")
            }
            type="text"
            value={lastName}
            placeholder={t("Last Name")}
            onIonChange={e => setLastName(e.detail.value)}
          />
        </div>
        <div className="step-input-container details">
          <p className="step-input-title">{t("Date of Birth")}</p>
          <div className="dates">
            <IonSelect
              onIonChange={e => setYear(e.detail.value)}
              value={year}
              placeholder={t("YYYY")}
              className={
                "header-search date " +
                (year !== undefined && year === "" ? "wrong" : "")
              }
              interface="popover"
            >
              {years.map((el, i) => {
                return (
                  <IonSelectOption key={el} value={el + ""}>
                    {el}
                  </IonSelectOption>
                );
              })}
            </IonSelect>
            <IonSelect
              onIonChange={e => setMonth(e.detail.value)}
              value={month}
              placeholder={t("MM")}
              className={
                "header-search date " +
                (month !== undefined && month === "" ? "wrong" : "")
              }
              interface="popover"
            >
              {months.map((el, i) => {
                return (
                  <IonSelectOption key={el} value={++i + ""}>
                    {el}
                  </IonSelectOption>
                );
              })}
            </IonSelect>
            <IonSelect
              onIonChange={e => setDay(e.detail.value)}
              value={day}
              placeholder={t("DD")}
              className={
                "header-search date " +
                (day !== undefined && day === "" ? "wrong" : "")
              }
              interface="popover"
            >
              {dates.map((el, i) => {
                return (
                  <IonSelectOption key={el} value={el + ""}>
                    {el}
                  </IonSelectOption>
                );
              })}
            </IonSelect>
          </div>
        </div>
        <div className="step-input-container">
          <p className="step-input-title">{t("Country")}</p>
          <ReactFlagsSelect
            className={"header-search country"}
            searchable={true}
            onSelect={e => setCountry(e)}
            defaultCountry={props.data.userData.country}
            countries={permittedCountries}
            blackList={true}
            searchPlaceholder={t("Search for a country")}
          />
        </div>
        <div className="step-input-container">
          <p className="step-input-title">{t("Email")}</p>
          <IonInput
            className={
              "header-search " +
              (email !== undefined && email === "" ? "wrong" : "") +
              (emailInUse === true ? "wrong" : "")
            }
            type="email"
            value={email}
            placeholder="example@domain.com"
            onBlur={() => checkEmail()}
            onIonChange={e => setEmail(e.detail.value)}
          />
        </div>
        {emailInUse === true && (
          <p className="subtitle subtitle-alert">{emailFailure}</p>
        )}
        {
        //   <div className="step-input-container">
        //   <p className="step-input-title">Preferred Language</p>
        //   <ReactFlagsSelect
        //     className={"header-search country lan"}
        //     defaultCountry={props.data.userData.language}
        //     customLabels={{
        //       GB: "English",
        //       ES: "Spanish",
        //       IT: "Italian",
        //       DE: "German",
        //       NL: "Dutch",
        //       PL: "Polish",
        //       PT: "Portuguese",
        //       RU: "Russian",
        //       TR: "Turkish",
        //       FR: "French",
        //       CN: "Chinese",
        //       JP: "Japanese",
        //       AE: "Arabic"
        //     }}
        //     countries={[
        //       "GB",
        //       "ES",
        //       "IT",
        //       "DE",
        //       "NL",
        //       "PL",
        //       "PT",
        //       "RU",
        //       "TR",
        //       "FR",
        //       "CN",
        //       "JP",
        //       "AE"
        //     ]}
        //     onSelect={e => setLanguage(e)}
        //   />
        // </div>
      }
        {/* <div className="step-input-container">
          <p className="step-input-title">Currency</p>
          <IonSelect
            onIonChange={e => changeCurrency(e.detail.value)}
            value={currency}
            placeholder={"US Dollar"}
            className={
              "header-search country cur" +
              (currency !== undefined && currency === "" ? "wrong" : "")
            }
            interface="popover"
          >
            {props.data.currencies.map((el, i) => {
              return (
                <IonSelectOption key={i} value={el.id}>
                  {el.full_name}
                </IonSelectOption>
              );
            })}
          </IonSelect>
        </div>
        <p className="step-input-title large mrgbtn">Attention!</p>
        <p className="step-input-title large">
          When you change your preferred currency, your bonuses will also be
          converted to the new currency
        </p>*/}
        <div className="step-input-container avatar">
          <p className="step-input-title">{t("Avatar")}</p>
          <Previews
            pic={
              props.data.userData.avatar === null
                ? props.data.defaultAvatar
                : props.data.userData.avatar
            }
            getFiles={getFiles}
          />
        </div>
        <div className="lower">
          <IonItem
            button
            onClick={() => changePassword()}
            className="register-btn submit change-password"
          >
            <IonLabel>{t("Change password")}</IonLabel>
          </IonItem>
          <p onClick={() => save()} className="next-step unselectable">
          {t("Save")}
          </p>
        </div>
      </div>
      <IonToast
        isOpen={passwordChangedToast}
        onDidDismiss={() => setPasswordChangedToast(false)}
        message={t("Your password has been successfully changed.")}
        color="primary"
        duration={10000}
      />
      <IonToast
        isOpen={showToast1}
        onDidDismiss={() => setShowToast1(false)}
        message={t("Your data has been successfully changed.")}
        color="primary"
        duration={10000}
      />
      <IonToast
        isOpen={showToast2}
        onDidDismiss={() => setShowToast2(false)}
        message={showToast2Text}
        color="primary"
        duration={10000}
      />
      <IonToast
        isOpen={showToast3}
        onDidDismiss={() => setShowToast3(false)}
        message={t("Verification code was sent to your Email.")}
        color="primary"
        duration={10000}
      />
      <IonModal
        isOpen={changePasswordModal}
        cssClass="login-modal logreg recvr verific from-user-cabinet"
        onDidDismiss={() => setChangePasswordModal(false)}
      >
        <div className="modal-container">
          <div className="cards" />
          <div className="modal-container-title">{t('Change password')}</div>
          <IonInput
            className={"header-search username"}
            type="text"
            autocomplete={"off"}
            placeholder={t("Please enter verification code")}
            value={confirmation}
            onIonChange={e => setConfirmation(e.detail.value)}
          />
          <IonInput
            className={"header-search username"}
            type="password"
            autoComplete={"off"}
            placeholder={t("Please enter new password")}
            value={newPassword}
            onIonChange={e => setNewPassword(e.detail.value)}
          />
          <IonInput
            className={"header-search username"}
            type="password"
            autoComplete={"off"}
            placeholder={t("Please repeat new password")}
            value={newPasswordConfirm}
            onIonChange={e => setNewPasswordConfirm(e.detail.value)}
          />
          <div onClick={() => restoreConfirm()} className="login-btn">
            {t("Change password")}
          </div>
        </div>
      </IonModal>
    </div>
  );
}

export default PersonalDetails;
