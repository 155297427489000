import React, { useState, useEffect } from "react";
import { IonItem, IonLabel } from "@ionic/react";
import multipleIcon from "../../../images/icons/team.svg";
import { Card, CardBody } from "shards-react";
import { useTranslation } from "react-i18next";


function MultipleLogins(props) {
  const [showFrom, setShowFrom] = useState(0);
  const { t } = useTranslation();
  const [totalLength, setTotalLength] = useState([]);
  useEffect(() => {
    let start = 1;
    let result = [];
    while (start <= Math.ceil(props.logins.length / 10)) {
      result.push(start++);
    }
    setTotalLength(result);
  }, [props.logins]);
  props.setShowLoading(false);
  return (
    <div className="deposit-money">
      <div className="shade">
        <p className="top-shade">
          <img alt="multiple logins" src={multipleIcon} />
        </p>
      </div>
      <p className="title">
        <span>{t('Account Details')}</span>
      </p>
      <p className="title-2">{t('Multiple logins')}</p>
      <div className="account-details-container multiple-logins">
        {
          // props.logins.map((el,i)=> {
          //   return(
          //     <div className='logs-container' key={i}>
          //       <div className='data'>
          //         <p>{el.device === "Other" ? el.os : el.device}</p>
          //         <p>IP Address {el.device_ip}</p>
          //       </div>
          //       <div className='data-where'>
          //         <p>{el.current_session === "yes" ? "This computer" : ''}</p>
          //       </div>
          //     </div>
          //   )
          // })
        }
        {props.logins.length > 0 && (
          <Card className="card-table">
            <CardBody className="p-0 pb-3">
              <table className="table mb-0">
                <thead className="card-header-main">
                  <tr>
                    <th scope="col" className="border-0">
                    {t('Device')}
                    </th>
                    <th scope="col" className="border-0">
                      {t('IP Address')}
                    </th>
                    <th scope="col" className="border-0">
                      {t('Current device')}
                    </th>
                    <th scope="col" className="border-0">
                      {t('Action')}
                    </th>
                  </tr>
                </thead>
                <tbody>
                  {props.logins.map((el, i) => {
                    if (i >= showFrom && i < showFrom + 10) {
                      return (
                        <tr key={i}>
                          <td>{el.device === "Other" ? el.os : el.device}</td>
                          <td>{el.device_ip}</td>
                          <td>{el.current_session === "yes" ? "✔" : ""}</td>
                          <td>
                            <IonItem
                              button
                              onClick={() => console.log()}
                              className="register-btn submit"
                            >
                              <IonLabel>  {t('Logout')} </IonLabel>
                            </IonItem>
                          </td>
                        </tr>
                      );
                    }
                    return null;
                  })}
                </tbody>
              </table>
            </CardBody>
            {props.logins.length > 10 && (
              <div className="card-paginator">
                <div onClick={() => setShowFrom(0)} className="arrow">
                  {"<"}
                </div>
                <div className="paginator-container">
                  {totalLength.map((el, i) => {
                    return (
                      <div onClick={() => setShowFrom(i * 10)} key={i}>
                        {i + 1}
                      </div>
                    );
                  })}
                </div>
                <div
                  onClick={() =>
                    setShowFrom(
                      (props.logins.length -
                        (props.logins.length - (totalLength.length - 1))) *
                        10
                    )
                  }
                  className="arrow"
                >
                  {">"}
                </div>
              </div>
            )}
          </Card>
        )}
      </div>
    </div>
  );
}

export default MultipleLogins;
