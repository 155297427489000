import React from "react";
import { IonItem, IonLabel } from "@ionic/react";
import { useHistory } from "react-router-dom";
import url from "../../../axios.js";
import axios from "axios";
import closeIcon from "../../../images/icons/close.svg";
import { useTranslation } from "react-i18next";
function CloseAccount(props) {
  const { t } = useTranslation();
  let history = useHistory();
  function closeAccount() {
    props.setShowLoading(true);
    axios({
      method: "post",
      url: url + "/api/close-user",
      headers: {
        Authorization: `Bearer ${props.data.token}`
      }
    }).then(res => {
      if (res.data.status === 1) {
        props.clearState();
        history.push("/home");
        props.setShowLoading(false);
      }
    });
  }
  return (
    <div className="deposit-money">
      <div className="shade">
        <p className="top-shade">
          <img alt="close account" src={closeIcon} />
          
        </p>

      </div>
      <p className="title">
        <span>{t('Account Closure')}</span>
      </p>
      <div className="account-details-container closure">
        <p>{t('Please be aware, after you deactivate your account')}: </p>
        <p>{t('You will not be able to log in or open a new account.')}</p>
        <p>{t('Pending bets will be settled as normal.')} </p>
        <p>{t('You will not be able to withdraw money.')}</p>
        <p>
          {t('If you decide to come back to Bingo.bet you need to contact customer service.')}
        </p>
        <p className="alert-text">
          {t('Make sure you’re happy with your decision before you confirm your account closure. This action cannot be undone.')}
        </p>
      </div>
      <IonItem
        button
        onClick={() => closeAccount()}
        className="register-btn closure-btn"
      >
        <IonLabel>{t('Confirm Closure')}</IonLabel>
      </IonItem>
    </div>
  );
}

export default CloseAccount;
