import React from 'react';
import {  IonSpinner } from '@ionic/react';

export default function UserSpinner(props) {
  return (
      <>{
        props.showLoading === true &&
        <div className="spinner-box">
          <div className="logo"></div>
          <IonSpinner
          // isOpen={true}
          name={'crescent'}
          color={'ion-color-banner'}
          // duration={10000}
          cssClass={'loading-hidden for-user-cabinet'}/>
        </div>
        }
        </>
  )
}
