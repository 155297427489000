import Menu from "./components/Menu";
import Home from "./pages/Home.js";
import Register from "./pages/Register.js";
import Documents from "./pages/Documents.js";
import Promo from "./pages/Promo.js";
import User from "./pages/user/User.js";
import Chat from "./chat/Chat.js";
import React from "react";
// import ClearCache from 'react-clear-cache';
import {
  IonApp,
  IonRouterOutlet,
  IonSplitPane,
  IonLoading
} from "@ionic/react";
import { IonReactRouter } from "@ionic/react-router";
import { Redirect, Route } from "react-router-dom";
import axios from "axios";
import url from "./axios.js";
/* Core CSS required for Ionic components to work properly */
import "@ionic/react/css/core.css";

/* Basic CSS for apps built with Ionic */
import "@ionic/react/css/normalize.css";
import "@ionic/react/css/structure.css";
import "@ionic/react/css/typography.css";

/* Optional CSS utils that can be commented out */
import "@ionic/react/css/padding.css";
import "@ionic/react/css/float-elements.css";
import "@ionic/react/css/text-alignment.css";
import "@ionic/react/css/text-transformation.css";
import "@ionic/react/css/flex-utils.css";
import "@ionic/react/css/display.css";
import { FP } from "@fp-pro/client";
/* Theme variables */
import "./theme/variables.css";


class App extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      countries: [],
      token: null,
      logins: [],
      theme: false,
      userData: [],
      listPaymentSystem: [],
      balance: [],
      cookies: false,
      currencies: [],
      defaultAvatar:
        "https://sun1-84.userapi.com/towoR7THO0npdci8NZrl0zqn2-P5TgyzE07_Fg/xzOi-Ig9qWs.jpg",
      htmlString: "",
      showMainLoading: false
    };
  }
  setTheme = (prop) => {
    this.setState({ theme: prop });
  }
  getCurrencies = () => {
    axios({
      method: "post",
      url: url + "/api/get-currencies"
    }).then(res => {
      this.setState({ currencies: res.data });
    });
  };
  getPaymentSystems = (token) => {
  axios({
    method: 'post',
    url: url + '/api/get-paymentsystem-list',
    headers: {
         'Authorization': `Bearer ${token}`,
     }
  })
  .then(res => {
    this.setState({listPaymentSystem : res.data.data.listPaymentSystem})
  })

}
  // загрузка игры
  loadGame = (token) => {
    if(token === null){
      axios({
        method: "post",
        url: url + "/api/run-demo-game",
        // headers: {
        //   Authorization: `Bearer ${props.token}`
        // },
        data: {
          id: 1522,
          lang: localStorage.getItem("Lang")
        }
      }).then(res => {
        this.setState({ htmlString: res.data.data.html });
      });
    } else {
      axios({
        method: "post",
        url: url + "/api/run-game",
        headers: {
          Authorization: `Bearer ${token}`
        },
        data: {
          id: 1522,
          lang: localStorage.getItem("Lang"),
          currency:978
        }
      }).then(res => {
        this.setState({ htmlString: res.data.data.html });
      });
    }
  };

  getCountries = () => {
    axios({
      method: "post",
      url: url + "/api/permitted-countries"
    }).then(res => {
      // console.log(res);
      this.setState({ countries: res.data });
    });
  };
  setShowLoading = boo => {
    this.setState({ showMainLoading: boo });
  };
  setToken = (token, uuid) => {
    /*установка токена при логине*/
    this.setShowLoading(true);
    localStorage.setItem("token", token);
    let requestMetadata = { UUID: uuid };
      async function sendInfo() {
      let fp = await FP.load({client: "UCmjTCW278UaQTDLjsMJ", region: "eu"});
      let response = await fp.send({tag: requestMetadata, callbackData: true});

      return response;
    };

    // this.getGames(true, token);
    sendInfo()
      .then(()=> {
        window.location.reload();
      })


  };
  getBalance = (token) => {
    axios({
      method: "post",
      url: url + "/api/user",
      headers: {
        Authorization: `Bearer ${this.state.token}`
      }
    })
    .then(res => {
      this.setState({ balance: res.data.data.balance });
    })
  }
  getInfo = token => {
    axios({
      method: "post",
      url: url + "/api/user",
      headers: {
        Authorization: `Bearer ${token}`
      }
    })
      .then(res => {
        this.setState({ userData: res.data.data.player });
        this.setState({ balance: res.data.data.balance });
        this.setState({ token: token });
        this.setShowLoading(false);
      })
      .catch(error => {
        if (error.message === "Request failed with status code 401") {
          localStorage.removeItem("token");
          window.location.reload();
        }
      });
    axios({
      method: "post",
      url: url + "/api/multiple-logins",
      headers: {
        Authorization: `Bearer ${token}`
      }
    }).then(res => {
      this.setState({ logins: res.data.data.session.reverse() });
    });
  };
  componentDidMount() {
    if (localStorage.getItem("theme") === 'false') {
      this.setTheme(false);
      document.getElementsByTagName('body')[0].classList.remove("dark")
    } if (localStorage.getItem("theme") === 'true') {
      this.setTheme(true);
      document.getElementsByTagName('body')[0].classList.add("dark")
    }
    // } else {
    //   localStorage.setItem("theme", false)
    // }
    let reset = localStorage.getItem("reset");
    if (reset === null) {
      localStorage.setItem("reset", true);
      document.location.reload(true);
    }
    this.setState({ cookies: localStorage.getItem('cookies') });
    this.getCountries();
    this.getCurrencies();
    let lang = localStorage.getItem("Lang");
    if (lang === null) {
      localStorage.setItem("Lang", 'GB')
    }
    let token = localStorage.getItem("token");
    if (token !== null || this.state.token !== null) {
      this.getPaymentSystems(token);
      this.getInfo(token);
      setInterval(this.getBalance, 10000);
    };
    this.loadGame(token);
  }
  render() {
    return (
      <IonApp>
        <IonReactRouter>
          <Chat token={null}/>
          <Menu setShowLoading={this.setShowLoading} data={this.state} />
          <IonSplitPane contentId="main">
            <IonRouterOutlet id="main">
              <Route path="/promo"
                    render={() =>
                      <Promo/>
                    }
                    exact />
              <Route
                path="/home"
                render={() => (
                  <Home
                    theme={this.state.theme}
                    setTheme={this.setTheme}
                    cookies={this.state.cookies}
                    data={this.state}
                    htmlString={this.state.htmlString}
                    setShowLoading={this.setShowLoading}
                    setToken={this.setToken}
                  />
                )}
                exact
              />
              <Route
                path="/documents/:page"
                render={() => (
                  <Documents
                    theme={this.state.theme}
                    setTheme={this.setTheme}
                    data={this.state}
                    setShowLoading={this.setShowLoading}
                    setToken={this.setToken}
                  />
                )}
                exact
              />
              <Route
                exact
                path="/user/:page"
                render={() => (
                  <User
                    theme={this.state.theme}
                    setTheme={this.setTheme}
                    setMainLogin={this.setMainLogin}
                    changeAppLang={this.changeAppLang}
                    mode={this.state.mode}
                    toggleMode={this.toggleMode}
                    clearState={this.clearState}
                    logins={this.state.logins}
                    updateUser={this.getInfo}
                    setShowLoading={this.setShowLoading}
                    setToken={this.setToken}
                    setUuid={this.setUuid}
                    data={this.state}
                  />
                )}
              />
              <Route
                path="/register"
                render={() => (
                  <Register
                    theme={this.state.theme}
                    setTheme={this.setTheme}
                    data={this.state}
                    setShowLoading={this.setShowLoading}
                    setToken={this.setToken}
                  />
                )}
                exact
              />
              <Redirect from="/" to="/home" exact />
            </IonRouterOutlet>
          </IonSplitPane>
        </IonReactRouter>
        <IonLoading
          isOpen={this.state.showMainLoading}
          onDidDismiss={() => this.setShowLoading(false)}
          spinner={"dots"}
          duration={10000}
          cssClass={"loading-hidden"}
        />
      </IonApp>
    );
  }
}

export default App;
