import React,{useState} from 'react';
import moment from "moment";
import i18next from "i18next";
import { IonItem, IonLabel } from "@ionic/react";
const TablePart = (props) => {
  const [open, setOpen] = useState(false)
  function pickCurrency(currency_id) {
    if (currency_id === 840) {
      return "$";
    }
    if (currency_id === 978) {
      return "€";
    }
    if (currency_id === 643) {
      return "₽";
    }
    if (currency_id === 826) {
      return "£";
    }
  }
  return (
    <React.Fragment>
    <tbody >
      <tr>
        <td>
          {moment(props.el.created_at).format("L")},{" "}
          {moment(props.el.created_at).format("LT")}
        </td>
        <td>{props.el.amount_bet}</td>
        <td
          className={
            props.el.amount_win > 0 ? "win-bold" : ""
          }
        >
          {props.el.amount_win}{props.el.amount_win !== null && pickCurrency(props.el.currency_id)}
          {props.el.amount_win === null && i18next.t("In progress")}
        </td>

        <td>
          <IonItem
            button
            onClick={() => setOpen(!open)}
            className="register-btn"
          >
            <IonLabel>{i18next.t("More")}</IonLabel>
          </IonItem>

        </td>
      </tr>

    </tbody>
    <tbody className={"hideable-table " + (open ? '': 'closed')}>
      {JSON.parse(props.el.add_data).json_in && JSON.parse(props.el.add_data).json_in.action_details.events.map((item,i)=> {
      return(
        <tr  key={i}>
          <td className="tournament">
            <p><span className={"win-bold"}>{i18next.t('Tournament')}:</span> {item.tournamentName}</p>
            <p><span className={"win-bold"}>{i18next.t('Event')}:</span> {item.matchName}</p>
            <p><span className={"win-bold"}>{i18next.t('Odds')}:</span> {item.outcomeValue}</p>
            <p><span className={"win-bold"}>{i18next.t('Your bet')}:</span> {item.outcomeName}</p>
          </td>
          <td>

          </td>
          <td>

          </td>
          <td className="sport">
            <p><span className={"win-bold"}>{i18next.t('Sport')}:</span> {item.sportName}</p>
            <p><span className={"win-bold"}>{i18next.t('Bet type')}:</span> {item.marketName}</p>
            {props.el.amount_win !== null && <p><span className={"win-bold"}>{i18next.t('Result')}:</span> {props.el.amount_win > 0 ? i18next.t('win') : i18next.t('lose') }</p>}
            {props.el.amount_win === null && <p><span className={"win-bold"}>{i18next.t('Result')}:</span> {i18next.t('none')}</p>}
            <p><span className={"win-bold"}>{i18next.t('Status')}:</span> {props.el.amount_win === null ? i18next.t('In progress') : i18next.t('closed')}</p>
          </td>

        </tr>
      )
      }

      )}</tbody>
  </React.Fragment>
  )
}

export default TablePart
