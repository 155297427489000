import React, { useEffect, useState } from "react";
import { IonSelectOption, IonSelect, IonToast } from "@ionic/react";
import { useDropzone } from "react-dropzone";
import url from "../../../axios.js";
import axios from "axios";
import verifyIcon from "../../../images/icons/check.svg";
import { useTranslation } from "react-i18next";

function Previews(props) {
  const { t } = useTranslation();
  const [files, setFiles] = useState([]);
  const { getRootProps, getInputProps, isDragActive } = useDropzone({
    accept: "image/*",
    onDrop: acceptedFiles => {
      var file = acceptedFiles[0];
      const reader = new FileReader();
      reader.onload = event => {
        props.getFiles(event.target.result);
      };
      reader.readAsDataURL(file);

      setFiles(
        acceptedFiles.map(file =>
          Object.assign(file, {
            preview: URL.createObjectURL(file)
          })
        )
      );
    }
  });
  const style = {
    position: "absolute",
    width: "100%",
    height: "100%",
    borderRadius: "4px",
    border: "1px solid #707070"
  };
  const thumbs = files.map(file => (
    <div className={"thumb"} key={file.name}>
      <div
        style={{ backgroundImage: `url(${file.preview})` }}
        className={"peviewImg verify-prev"}
      />
    </div>
  ));

  useEffect(
    () => () => {
      // Make sure to revoke the data uris to avoid memory leaks
      files.forEach(file => URL.revokeObjectURL(file.preview));
    },
    [files]
  );

  return (
    <section className="file-zone-container">
      <div {...getRootProps({ className: "dropzone" })}>
        <input {...getInputProps()} />
        {isDragActive && files !== [] ? (
          <div className={"file-zone-active"}>
            <div className={"file-zone-img"} />
            {t('Drop file here')}
          </div>
        ) : (
          <div className={"file-zone"}>
            <div className={"file-zone-img"} />
          </div>
        )}
        {files !== [] && (
          <div style={style} className={"thumbs-container"}>
            {thumbs}
          </div>
        )}
      </div>
    </section>
  );
}

function VerifyAccount(props) {
    // console.log(props);
  const { t } = useTranslation();
  const [showToast1, setShowToast1] = useState(false);
  const [doc, setDoc] = useState("Driver Licence");
  var files = [];
  var files2 = [];
  var filesToSend = [];
  function getFiles(file) {
    files.push(file);
    filesToSend.push({ name: doc, images: file });
  }
  function getFiles2(file) {
    files2.push(file);
    filesToSend.push({ name: "doc", images: file });
  }
  function verifyAccount() {
    props.setShowLoading(true);
    if (filesToSend) {
      axios({
        method: "POST",
        url: url + "/api/document-upload",
        headers: {
          Authorization: `Bearer ${props.data.token}`,
          "Content-Type": "application/json"
        },
        data: { doc: filesToSend }
      }).then(res => {
        if (res.data.status === 1) {
          props.setShowLoading(false);
          setShowToast1(true);
        }
        props.setShowLoading(false);
      });
    }
  }
  return (
    <div className="deposit-money">
      <div className="shade">
        <p className="top-shade">
          <img alt="verify account" src={verifyIcon} />
        </p>
      </div>
      <p className="title">
        <span>{t('Account Details')}</span>
      </p>
      <p className="title-2">{t('Verify account')}</p>
      <div className="account-details-container verify">
        {
          props.data.userData.verified_status === 1 &&
          <p>
          {t('Your account is verified.')}
          </p>
        }
        {
          props.data.userData.verified_status === 2 &&
          <p>
          {t('Your request is being considered.')}
          </p>
        }
        {props.data.userData.verified_status === 0 &&
          <>
        <p>
        {t('You must complete our secure verification process to use your account.')}
        </p>
        <p>
          {t('We ask everyone to do this in order to meet gambling regulations and keep our players safe.')}
        </p>
        <p className="title-2">{t('Step 1. Provide proof of identity')}</p>
        <div className="step-input-container">
          <p className="step-input-title">{t('Select a document')}</p>
          <IonSelect
            onIonChange={e => setDoc(e.detail.value)}
            value={doc}
            placeholder={t('Select a document')}
            className={
              "header-search country doc" +
              (doc !== undefined && doc === "" ? "wrong" : "")
            }
            interface="popover"
          >
            <IonSelectOption value="Driver Licence">
              {t('Driver Licence')}
            </IonSelectOption>
            <IonSelectOption value="Passport">{t('Passport')}</IonSelectOption>
          </IonSelect>
        </div>
        <div className="previews-container">
          <Previews getFiles={getFiles} />
          <Previews getFiles={getFiles} />
        </div>
        {/*  <p onClick={() => next()}  className="next-step unselectable">Next ></p>*/}
        <p className="title-2">{t('Step 2. Provide proof of address')}</p>
        <p className="proof">
          {t("Upload one of the following documents showing your full name and physical address (we can’t accept a PO Box). Letters and bills must be dated within the last 6 months and show the company name or logo. Please cover personal information that isn’t relevant. Official letter from your bank, a bank statement or a credit card statement. Official letter from your government, police, magistrate, tax office or population registry, utility bill. We can accept water, electricity, gas, broadband or TV package bills but not a mobile phone bill.")}
        </p>
        <div className="previews-container previews-container-2">
          <Previews getFiles={getFiles2} />
        </div>
        <p
          onClick={() => verifyAccount()}
          className="next-step unselectable last-one"
        >
          {t("Save")}
        </p>
        </>
      }
      </div>
      <IonToast
        isOpen={showToast1}
        onDidDismiss={() => setShowToast1(false)}
        message={t("Your request has been successfully sent.")}
        color="primary"
        duration={10000}
      />
    </div>
  );
}

export default VerifyAccount;
