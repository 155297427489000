import React, { useState,useEffect } from "react";
import {
  IonInput,
  IonLabel,
  IonToast,
  IonItem,
  IonSelectOption,
  IonSelect
} from "@ionic/react";
import axios from "axios";
import url from "../../../axios.js";
import { useTranslation } from "react-i18next";
import depositIcon from "../../../images/icons/deposit2.svg";
import QRCode from 'qrcode.react';

function DepositMoney(props) {
  const { t } = useTranslation();
  const [showToast1, setShowToast1] = useState(false);
  const [showToast2, setShowToast2] = useState(false);
  const [showToast2Text, setShowToast2Text] = useState(false);
  const [moneyValue, setMoneyValue] = useState('');
  const [amount, setAmount] = useState('');
  const [currency, setCurrency] = useState(978);
  const [btcPrice, setBtcPrice] = useState(0);
  const [btcWallet, setBtcWallet] = useState('');
  function getCurrency(currency_id) {
    if (currency_id === 840) {
      return '$'
    }
    if (currency_id === 978) {
      return '€'
    }
    if (currency_id === 643) {
      return '₽'
    }
    if (currency_id === 826) {
      return '£'
    }
    if (currency_id === 'bitcoin') {
      return 'BTC'
    }
    if (currency_id === 'litecoin') {
      return 'LTC'
    }
    if (currency_id === "USDT") {
      return "USDT"
    }
    else {
      return currency_id
    }
  }
  useEffect(()=> {
    if (props.data.listPaymentSystem.some(e => e.payment_system_id === currency)) {
      axios({
        method: 'post',
        url: url + "/api/get-curs-crypto",
        data: {
          payment_system_id: currency,
          currency_entity: 'EUR'
        },
        headers: {
          'Authorization': `Bearer ${props.data.token}`,
        },
      }).then(res => {
          setBtcPrice(res.data.curs.price);
        })
    };
    setBtcWallet('');
  },[currency]);
  function getBtcWallet() {
    axios({
      method: 'post',
      url: url + "/api/get-address-payment",
      headers: {
        'Authorization': `Bearer ${props.data.token}`,
      },
      data: {
        payment_system_id: currency
      }
    })
      .then(res => {
        if (res.data.status === 1) {
          setBtcWallet(res.data.data.address);
        }
      }
    )
    .catch(error => {
      if (error.response.data.status === 422) {
        setShowToast2Text(error.response.data.error);
        setShowToast2(true);
      }
    })
  };
  function deposit() {
    if (moneyValue === undefined || moneyValue === "") {
      setMoneyValue("");
    }
    if (moneyValue !== undefined && moneyValue !== "") {
      props.setShowLoading(true);
      axios({
        method: "post",
        url: url + "/api/deposit-money",
        headers: {
          Authorization: `Bearer ${props.data.token}`
        },
        data: {
          currency: currency,
          amount: amount
        }
      })
        .then(res => {
          props.updateUser(props.data.token);
          setShowToast1(true);
          setMoneyValue(null);
          props.setShowLoading(false);
        })
        .catch(error => {
          /*422*/
          props.setShowLoading(false);
          setShowToast2Text(error.response.data.error);
          if (error.response.status === 500) {
            setShowToast2Text(t("Please validate your account"));
          }
          setShowToast2(true);
        });
      props.setShowLoading(false);
    }
  }

  function withNulls(e) {
    setMoneyValue(e.detail.value);
    setAmount(e.detail.value);
    // (+e.detail.value).toFixed(2)
  };
  return (
    <div className="deposit-money">
      <div className="shade">
        <p className="top-shade">
          <img alt="deposit" src={depositIcon} className="deposit-icon" />
        </p>
      </div>
      <p className="title">
        <span>{t("Deposit money")}</span>
      </p>
      <div className="account-details-container closure">
        <div className="step-input-container">

          <p  className="block step-input-title">{t("Currency")}</p>
        <IonSelect
          onIonChange={e => {setCurrency(e.detail.value)}}
          placeholder={t("Currency")}
          value={currency}
          className={
            "header-search gender currency"
          }
          interface="popover"
        >
        {
          props.data.listPaymentSystem.map((el,i)=> {
            return <IonSelectOption key={i} value={el.payment_system_id}>{el.name}</IonSelectOption>
          })
        }
        {props.data.currencies.map((el, i) => {
          return (
            <IonSelectOption key={i} value={el.id}>
              {el.full_name}
            </IonSelectOption>
          );
        })}
        </IonSelect>
      </div>
        <div className="step-input-container">

          <p  className="block step-input-title">{t("Value")}, {getCurrency(currency)}</p>
          <IonInput
            className={
              "money-value head er-search " +
              (moneyValue !== undefined && moneyValue === "" ? "wrong" : "")
            }
            type="text"
            value={moneyValue}
            placeholder={t("Value")}
            onIonChange={e => withNulls(e)}
            onIonBlur={() =>
              setMoneyValue(
                (+moneyValue).toFixed(2) === "NaN"
                  ? "0.00"
                  : (+moneyValue).toFixed(2)
              )
            }
          />
        </div>
        <div className="step-input-container">
          {!props.data.listPaymentSystem.some(e => e.payment_system_id === currency) &&
            <IonItem
              button
              onClick={() => deposit()}
              className="register-btn submit depst d-btn"
            >
            <IonLabel>{t("Deposit")}</IonLabel>
          </IonItem>
          }
          {props.data.listPaymentSystem.some(e => e.payment_system_id === currency) &&
            <IonItem
              button
              onClick={() => getBtcWallet()}
              className="register-btn submit depst d-btn"
            >
            <IonLabel>{t("Deposit")}</IonLabel>
          </IonItem>
          }
        </div>
        {props.data.listPaymentSystem.some(e => e.payment_system_id === currency) &&
          moneyValue !== '' &&
          <>
          <p className='balance-content'>
            <span className='green'>{moneyValue} </span>
              <span className='crypto'>{getCurrency(currency)}</span> is approximately
              <span className='green'> {(moneyValue * btcPrice).toFixed(2)} </span>
                EUR.</p>
              <p className='balance-content'>
                The exchange rate will be specified at the time of enrollment</p>
              </>}
          {btcWallet !== '' && props.data.listPaymentSystem.some(e => e.payment_system_id === currency) && <p className='balance-content-deposit'>Deposit via <span className='green pointer'>{btcWallet}</span></p>}
          {btcWallet !== '' && props.data.listPaymentSystem.some(e => e.payment_system_id === currency) && <QRCode renderAs={'svg'} className='qr' value={btcWallet}/>}

      </div>
      <IonToast
        isOpen={showToast1}
        onDidDismiss={() => setShowToast1(false)}
        message={t("Your deposit has been submitted.")}
        color="success"
        duration={10000}
      />
      <IonToast
        isOpen={showToast2}
        onDidDismiss={() => setShowToast2(false)}
        message={t(showToast2Text)}
        color="danger"
        duration={5000}
      />
    </div>
  );
}

export default DepositMoney;
