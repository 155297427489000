import React, {useState,useEffect} from 'react';
import { useLocation } from 'react-router-dom';
import Header from "../components/Header.js";
import Footer from "../components/Footer.js";
import axios from "axios";
import url from "../axios.js";
import UserSpinner from "../components/UserLoading.js";

import { IonContent, IonPage  } from "@ionic/react";

const Documents = (props) => {
  const [showLoading, setShowLoading] = useState(false);
  const [text, setText] = useState([]);
  let location = useLocation();
  function getDocs(){
      if (location.pathname === '/documents/terms') {
        setShowLoading(true);
        axiosDoc(1);
      }
      if (location.pathname === '/documents/policy') {
        setShowLoading(true);
        axiosDoc(3);
      }
      if (location.pathname === '/documents/contributions') {
        setShowLoading(true);
        axiosDoc(2);
      }
      if (location.pathname === '/documents/cookie-policy') {
        setShowLoading(true);
        axiosDoc(6);
      }
      if (location.pathname === '/documents/disclaimer') {
        setShowLoading(true);
        axiosDoc(7);
      }
      if (location.pathname === '/documents/responsible-policy') {
        setShowLoading(true);
        axiosDoc(8);
      }
      if (location.pathname === '/documents/kyc-policy') {
        setShowLoading(true);
        axiosDoc(9);
      }
      if (location.pathname === '/documents/tournaments') {
        setShowLoading(true);
        axiosDoc(11);
      }
      if (location.pathname === '/documents/data-protection') {
        setShowLoading(true);
        axiosDoc(12);
      }
      if (location.pathname === '/documents/help') {
        setShowLoading(true);
        axiosDoc(13);
      }
      if (location.pathname === '/documents/anti-money-laundering') {
        setShowLoading(true);
        axiosDoc(14);
      }
    }
    function axiosDoc(id) {
      axios({
            method: 'post',
            url: url + '/api/get-documents',
            data: {
              id: id,
              lang: 'GB'
            }
          })
          .then(res => {
            setText(JSON.parse(Object.values(res.data.data)));
            setShowLoading(false)
           })
    }
  useEffect(() => {
    getDocs();
  },[location.pathname])
  function toggleHeight(e, i, content) {
    if (content !== null) {
      let count = +e.target.parentElement.getAttribute("data-p");
      document.getElementsByClassName("paragraph")[count].classList.toggle('opened');
    }
  }
  return (
    <IonPage>
      <IonContent fullscreen>
        <Header
          theme={props.theme}
          setTheme={props.setTheme}
          data={props.data}
          setShowLoading={props.setShowLoading}
          setToken={props.setToken}
        />
        <div className="bets-container terms">
        <UserSpinner showLoading={showLoading} />
        {text.map((el,i) => {
          return (
            <span className={"paragraph " + (el.opened === true ? 'opened' : '')} data-p={i} key={i}>
              {el.name !== '' ? <h3 className={(el.contents === null ? '': 'h3-smaller')} onClick={(e) => toggleHeight(e, i, el.contents )}>{el.name}</h3> :'' }
              {el.contents !== '' ? <p dangerouslySetInnerHTML={{__html: el.contents}}></p>: '' }
            </span>
          )
        })}
        </div>
        {location.pathname.includes('/documents') && <Footer />}
      </IonContent>
    </IonPage>
  )
}

export default Documents
