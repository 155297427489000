import React, { useState } from "react";
import { IonSelect, IonSelectOption, IonInput, IonToast } from "@ionic/react";
import url from "../../../axios.js";
import axios from "axios";
import limitIcon from "../../../images/icons/limit.svg";
import { useTranslation } from "react-i18next";

function GamesLimit(props) {
  const { t } = useTranslation();
  const limits = ["10", "20", "30", "40", "50", "60", "70", "80"];
  const [showToast1, setShowToast1] = useState(false);
  const [showToast2, setShowToast2] = useState(false);
  const [showToast2Text, setShowToast2Text] = useState(false);
  const [monthly, setMonthly] = useState(
    checkCustom(props.data.userData.month_limit)
  );
  const [weekly, setWeekly] = useState(
    checkCustom(props.data.userData.week_limit)
  );
  const [daily, setDaily] = useState(
    checkCustom(props.data.userData.day_limit)
  );
  const [customMonthly, setCustomMonthly] = useState(
    props.data.userData.month_limit
  );
  const [customWeekly, setCustomWeekly] = useState(
    props.data.userData.week_limit
  );
  const [customDaily, setCustomDaily] = useState(props.data.userData.day_limit);
  const [depositLimit, setDepositLimit] = useState(0);
  const [customDeposit, setCustomDeposit] = useState(0);
  const [withdrawLimit, setWithdrawLimit] = useState(0);
  const [customWithdraw, setCustomWithdraw] = useState(0);
  function checkCustom(value) {
    return limits.indexOf(value) !== -1 ? value : "custom";
  }
  let checkDaily = e => {
    setDaily(e);
  };
  let checkWeekly = e => {
    setWeekly(e);
  };
  let checkMonthly = e => {
    setMonthly(e);
  };
  let checkDeposit = e => {
    setDepositLimit(e);
  };
  let checkWithdraw = e => {
    setWithdrawLimit(e);
  };
  function getCurrency(currency_id) {
    if (currency_id === 840) {
      return "$";
    }
    if (currency_id === 978) {
      return "€";
    }
    if (currency_id === 643) {
      return "₽";
    }
    if (currency_id === 826) {
      return "£";
    }
  }
  function save() {
    if (monthly === undefined || monthly === "") {
      setMonthly("");
    }
    if (daily === undefined || daily === "") {
      setDaily("");
    }
    if (weekly === undefined || weekly === "") {
      setWeekly("");
    }
    if (
      monthly !== undefined &&
      monthly !== "" &&
      daily !== undefined &&
      daily !== "" &&
      weekly !== undefined &&
      weekly !== ""
    ) {
      var data = {
        day_limit: daily === "custom" ? customDaily.toString() : daily.toString(),
        week_limit: weekly === "custom" ? customWeekly.toString() : weekly.toString(),
        month_limit: monthly === "custom" ? customMonthly.toString() : monthly.toString()
      };
      axios({
        method: "post",
        url: url + "/api/update-profile",
        headers: {
          Authorization: `Bearer ${props.data.token}`
        },
        data: data
      })
        .then(res => {
          if (res.data.status === 1) {
            props.updateUser(props.data.token);
            setShowToast1(true);
          }
        })
        .catch(error => {
          /*422*/
          setShowToast2Text(error.response.data.error);
          setShowToast2(true);
        });
    }
  }
  return (
    <div className="deposit-money limits">
      <div className="shade">
        <p className="top-shade">
          <img alt="games limit" src={limitIcon} />
        </p>
      </div>
      <p className="title">
        <span>{t('Account Details')}</span>
      </p>
      <p className="title-2">{t('Games limit')}, {getCurrency(props.data.userData.currency_id)}</p>
      <div className="account-details-container games-limit">
        <div className="step-input-container">
          <p className="step-input-title">{t('Daily Limit')}</p>
          <div className="custom-block">
            <IonSelect
              lines="none"
              onIonChange={e => checkDaily(e.detail.value)}
              value={daily}
              placeholder={t('Not Specified')}
              className={
                "header-search custom-input " +
                (daily !== undefined && daily === "" ? "wrong" : "")
              }
              interface="popover"
            >
            <IonSelectOption lines="none" value={'custom'}>
              {t('Custom Limit')}
            </IonSelectOption>
              {limits.map(el => {
                return (
                  <IonSelectOption lines="none" key={el} value={el}>
                    {el}
                  </IonSelectOption>
                );
              })}
            </IonSelect>
            {daily === "custom" && (
              <IonInput
                className={"header-search mobilecode custom-limit "}
                type="tel"
                value={customDaily}
                placeholder={t('Custom Limit')}
                onIonChange={e => setCustomDaily(e.detail.value)}
              />
            )}
          </div>
        </div>
        <div className="step-input-container">
          <p className="step-input-title">{t('Weekly Limit')}</p>
          <div className="custom-block">
            <IonSelect
              lines="none"
              onIonChange={e => checkWeekly(e.detail.value)}
              value={weekly}
              placeholder={t('Not Specified')}
              className={
                "header-search custom-input " +
                (weekly !== undefined && weekly === "" ? "wrong" : "")
              }
              interface="popover"
            >
            <IonSelectOption lines="none" value={'custom'}>
              {t('Custom Limit')}
            </IonSelectOption>
              {limits.map(el => {
                return (
                  <IonSelectOption lines="none" key={el} value={el * 5}>
                    {el * 5}
                  </IonSelectOption>
                );
              })}
            </IonSelect>
            {weekly === "custom" && (
              <IonInput
                className={"header-search mobilecode custom-limit"}
                type="tel"
                value={customWeekly}
                placeholder={t('Custom Limit')}
                onIonChange={e => setCustomWeekly(e.detail.value)}
              />
            )}
          </div>
        </div>
        <div className="step-input-container">
          <p className="step-input-title">{t('Monthly Limit')}</p>
          <div className="custom-block">
            <IonSelect
              lines="none"
              onIonChange={e => checkMonthly(e.detail.value)}
              value={monthly}
              placeholder={t('Not Specified')}
              className={
                "header-search custom-input " +
                (monthly !== undefined && monthly === "" ? "wrong" : "")
              }
              interface="popover"
            >
              <IonSelectOption lines="none" value={'custom'}>
                {t('Custom Limit')}
              </IonSelectOption>
              {limits.map(el => {
                return (
                  <IonSelectOption lines="none" key={el} value={el * 20}>
                    {el * 20}
                  </IonSelectOption>
                );
              })}
              <IonSelectOption className="hide" value={"custom"}>
                {t('Custom Limit')}
              </IonSelectOption>
            </IonSelect>
            {monthly === "custom" && (
              <IonInput
                className={"header-search mobilecode custom-limit "}
                type="tel"
                value={customMonthly}
                placeholder={t('Custom Limit')}
                onIonChange={e => setCustomMonthly(e.detail.value)}
              />
            )}
          </div>
        </div>
        <div className="lower">
          <p onClick={() => save()} className="next-step unselectable">
            {t('Save')}
          </p>
        </div>
      </div>

      <p className="title-2">{t('Deposit Limit')}, {getCurrency(props.data.userData.currency_id)}</p>
      <div className="account-details-container games-limit deposit-limit">
        <div className="step-input-container">
          <p className="step-input-title">{t('Limit')}</p>
          <div className="custom-block">
            <IonSelect
              lines="none"
              onIonChange={e => checkDeposit(e.detail.value)}
              value={depositLimit}
              placeholder={t('Not Specified')}
              className={
                "header-search custom-input " +
                (depositLimit !== undefined && depositLimit === ""
                  ? "wrong"
                  : "")
              }
              interface="popover"
            >
            <IonSelectOption value={"custom"}>
              {t('Custom Limit')}
            </IonSelectOption>
              {limits.map(el => {
                return (
                  <IonSelectOption lines="none" key={el} value={el}>
                    {el}
                  </IonSelectOption>
                );
              })}

            </IonSelect>
            {depositLimit === "custom" && (
              <IonInput
                className={"header-search mobilecode custom-limit "}
                type="tel"
                value={customDeposit}
                placeholder={t('Custom Limit')}
                onIonChange={e => setCustomDeposit(e.detail.value)}
              />
            )}
          </div>
        </div>
        <div className="lower">
          <p className="next-step unselectable">{t('Save')}</p>
        </div>
      </div>

      <p className="title-2">{t('Withdraw Limit')}, {getCurrency(props.data.userData.currency_id)}</p>
      <div className="account-details-container games-limit withdraw-limit">
        <div className="step-input-container">
          <p className="step-input-title">{t('Limit')}</p>
          <div className="custom-block">
            <IonSelect
              lines="none"
              onIonChange={e => checkWithdraw(e.detail.value)}
              value={withdrawLimit}
              placeholder={t('Not Specified')}
              className={
                "header-search custom-input " +
                (withdrawLimit !== undefined && withdrawLimit === ""
                  ? "wrong"
                  : "")
              }
              interface="popover"
            >
            <IonSelectOption value={"custom"}>
              {t('Custom Limit')}
            </IonSelectOption>
              {limits.map(el => {
                return (
                  <IonSelectOption lines="none" key={el} value={el}>
                    {el}
                  </IonSelectOption>
                );
              })}

            </IonSelect>
            {withdrawLimit === "custom" && (
              <IonInput
                className={"header-search mobilecode custom-limit "}
                type="tel"
                value={customWithdraw}
                placeholder={t('Custom Limit')}
                onIonChange={e => setCustomWithdraw(e.detail.value)}
              />
            )}
          </div>
        </div>
        <div className="lower">
          <p className="next-step unselectable">{t('Save')}</p>
        </div>
      </div>

      <IonToast
        isOpen={showToast1}
        onDidDismiss={() => setShowToast1(false)}
        message={t('Your data has been successfully changed.')}
        color="primary"
        duration={10000}
      />
      <IonToast
        isOpen={showToast2}
        onDidDismiss={() => setShowToast2(false)}
        message={showToast2Text}
        color="primary"
        duration={10000}
      />
    </div>
  );
}

export default GamesLimit;
