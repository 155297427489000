import axios from 'axios';
import { FP } from "@fp-pro/client";
const url = 'https://test.sbet.biz';
const { v4: uuidv4 } = require('uuid');

export async function getMessages(id){
  let response = await axios({
    method: 'post',
    url: url + '/api/get-chat-messages',
    // headers: {
    //      'Authorization': `Bearer ${token}`,
    //  },
    data: {
      chat_id: id,
    }
  });
  return response;
}

export async function getChatId(name) {
  var uuid = uuidv4();
  let requestMetadata = { UUID: uuid };
  let fp = await FP.load({ client: "UCmjTCW278UaQTDLjsMJ", region: "eu" });
  let result = await fp.send({ tag: requestMetadata, callbackData: true });
  let response = axios({
    method: 'post',
    url: url + '/api/get-chat-id',
    data: {
      title: name,
      fingerprint: JSON.stringify(result)
    }
  })
  return response
};

export async function sendMessage(chatId, message) {
  let response = axios({
    method: 'post',
    url: url + '/api/send-chat-message',
    data: {
      message: message,
      chat_id: chatId
    }
  })
  return response
}
