import React, {useEffect, useState} from "react";
import PieChart from '@bit/kyenx.playground.pie-chart';
import Chart from '@bit/nexxtway.react-rainbow.chart';
import moment from 'moment';
import { useTranslation } from "react-i18next";
import Dataset from '@bit/nexxtway.react-rainbow.dataset';
function DataChart(props){
  const { t } = useTranslation();
  const [show, setShow] = useState(false);
  const [show2, setShow2] = useState(false);
  const [graphData, setGraphData] = useState({labels:[], values1:[], values2:[]});
  const [pieData, setPieData] = useState([{label: 'Group A', value: 750},{label: 'Group B', value: 84}])
  const chartLabel = `${(props.value2.toFixed(1) - props.value1.toFixed(1)).toFixed(1) +" "+ pickCurrency(props.data.userData.currency_id)}`;
  useEffect(() => {
    if (props.transactions.lenght !== 0) {
      setShow2(true);
    }
    if (props.transactions.lenght === 0) {
      setShow2(false);
    }
    if (props.value1 !== 0) {
      setShow(true);
      setPieData([
          {
          label: props.label1,
          value: props.value1
        },
        {
          label: props.label2,
          value: props.value2
        }
      ])
    }
    if (props.value1 === 0) {
      setShow(false)
    }
    /*eslint-disable*/
  },[props.label1, props.label2, props.transactions.lenght, props.value1, props.value2])
  /*eslint-enable*/
  useEffect(() => {
    if (props.transactions.length > 1 ) {
      setGraphData(
        {
          labels:props.transactions.map(el => {return el.date}),
          values1:props.transactions.map(el => {return +el.amount}),
          values2:props.transactions.map(el => {return +el.amount})}
        );
      setShow2(true);
    }
    if (props.transactions.length === 0) {
      setShow2(false);
    }
  },[props.transactions])
  function pickCurrency(currency_id) {

    if (currency_id === 840) {
      return '$'
    }
    if (currency_id === 978) {
      return '€'
    }
    if (currency_id === 826) {
      return '£'
    }
  }
    return (
      <div className="graphs">
      {
        show === true &&
        <div className='pie'>
          <PieChart data={pieData} chartLabel={chartLabel}/>
          <p>{t("Total")} {t(props.label1)}: {props.value1.toFixed(1)} {pickCurrency(props.data.userData.currency_id)}</p>
          <p>{t("Total")} {t(props.label2)}: {props.value2.toFixed(1)} {pickCurrency(props.data.userData.currency_id)}</p>
        </div>
      }
      {
        show2 === true &&
        <div className='chart-2'>
          <div>
              <Chart
                  labels={props.transactions.map(el => {
                    return moment(el.date).format('DD.MM')
                  }).reverse()}
                  type="line"
              >
                  <Dataset
                      title="Deposit"
                      values={props.transactions.map(el => {if (el.operation === "deposit") {
                          return +el.amount
                        }
                        return null;
                      }).reverse()}
                      backgroundColor="#1de9b6"
                      borderColor="#1de9b6"
                  />
                  <Dataset
                      title="Withdrawal"
                      values={props.transactions.map(el => {if (el.operation === "withdrawal") {
                          return +el.amount
                        }
                        return null;
                      }).reverse()}
                      backgroundColor="#01b6f5"
                      borderColor="#01b6f5"
                  />
              </Chart>
          </div>
        </div>
      }
      </div>
    );
}


export default DataChart;
