import React from "react";
import { Link } from "react-router-dom";
import { Row, Col, FormSelect, Card, CardBody, CardFooter } from "shards-react";
import axios from "axios";
import ApexChart from "./BarChart.js";
import url from "../axios.js";
import moment from "moment";
import Chart from "../utils/Chart.js";
import i18next from "i18next";
var chart;
var depositWithdraw;
class UsersByDevice extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      deposit: 50,
      withdrawal: 50,
      transactionsHist: [],
      gamesHist: [],
      bets: 0,
      wins: 0,
      topGames: [],
      value: "months",
      value2: "months",
      options: {
        ...{
          legend: {
            position: "bottom",
            labels: {
              padding: 25,
              boxWidth: 20
            }
          },
          cutoutPercentage: 0,
          tooltips: {
            custom: false,
            mode: "index",
            position: "nearest"
          }
        },
        ...this.props.chartOptions
      },
      depositWithdrawValue: "months"
    };
    this.canvasRef = React.createRef();
    this.canvasRef2 = React.createRef();
  }
  changePeriod2 = period => {
    axios({
      method: "post",
      url: url + "/api/history-bets",
      headers: {
        Authorization: `Bearer ${this.props.data.token}`
      },
      data: {
        date_from: moment()
          .subtract(period === "days" ? 7 : 1, period)
          .unix(),
        date_to: moment(new Date()).unix()
      }
    }).then(res => {
      this.setState({
        gamesHist: res.data.data.history
      });
      if (res.data.data.history.length !== 0) {
        let bets = 0;
        let wins = 0;
        res.data.data.history.forEach((item, i) => {
          bets += +item.amount_bet;
          if (item.amount_win !== null) {
            wins += +item.amount_win;
          }
        });
        this.setState({
          bets: (bets*100/(bets+wins)).toFixed(2),
          wins: (wins*100/(bets+wins)).toFixed(2)
        });

        this.updateConfigByMutating(
          depositWithdraw,
          this.state.bets,
          this.state.wins
        );
      }
    });
  };
  changePeriod = period => {
    axios({
      method: "post",
      url: url + "/api/history-transactions",
      headers: {
        Authorization: `Bearer ${this.props.data.token}`
      },
      data: {
        date_from: moment()
          .subtract(period === "days" ? 7 : 1, period)
          .unix(),
        date_to: moment(new Date()).unix()
      }
    }).then(res => {
      this.setState({
        transactionsHist: res.data.data.history
      });
      let deposit =
        (res.data.data.history.filter(i => i.operation === "deposit").length /
          res.data.data.history.length) *
        100;
      let withdrawal =
        (res.data.data.history.filter(i => i.operation === "withdrawal")
          .length /
          res.data.data.history.length) *
        100;
      if (res.data.data.history.length !== 0) {
        // console.log(res.data.data.history);
        this.setState({
          deposit: deposit.toFixed(2),
          withdrawal: withdrawal.toFixed(2)
        });
        this.updateConfigByMutating(
          chart,
          this.state.deposit,
          this.state.withdrawal
        );
      }
    });
  };
  updateConfigByMutating = (chart, val1, val2) => {
    chart.data.datasets = [
      {
        hoverBorderColor: "#ffffff",
        data: [val1, val2],
        backgroundColor: ["#fe9c26", "#F25D31"]
      }
    ];
    chart.update();
  };
  getData = () => {
    this.props.setShowLoading(true);
    // axios({
    //   method: "post",
    //   url: url + "/api/top-games",
    //   headers: {
    //     Authorization: `Bearer ${this.props.data.token}`
    //   },
    //   data: {
    //     date_from: moment()
    //       .subtract(7, "days")
    //       .unix(),
    //     date_to: moment(new Date()).unix()
    //   }
    // }).then(res => {
    //   this.setState({
    //     topGames: res.data.data.topGames
    //   });
    // });
    axios({
      method: "post",
      url: url + "/api/history-transactions",
      headers: {
        Authorization: `Bearer ${this.props.data.token}`
      },
      data: {
        date_from: moment()
          .subtract(1, "months")
          .unix(),
        date_to: moment(new Date()).unix()
      }
    }).then(res => {
      this.setState({
        transactionsHist: res.data.data.history
      });
      this.props.setShowLoading(false);
      let deposit =
        (res.data.data.history.filter(i => i.operation === "deposit").length /
          res.data.data.history.length) *
        100;
      let withdrawal =
        (res.data.data.history.filter(i => i.operation === "withdrawal")
          .length /
          res.data.data.history.length) *
        100;
      if (res.data.data.history.length !== 0) {
        this.setState({
          deposit: deposit.toFixed(2),
          withdrawal: withdrawal.toFixed(2)
        });
      }

      const chartConfig = {
        type: "pie",
        data: {
          datasets: [
            {
              hoverBorderColor: "#ffffff",
              data: [this.state.deposit, this.state.withdrawal],
              backgroundColor: ["#fe9c26", "#F25D31"]
            }
          ],
          labels: [i18next.t('Deposit, %'), i18next.t('Withdrawal, %')]
        },
        options: this.state.options
      };
      chart = new Chart(this.canvasRef.current, chartConfig);
    });
    axios({
      method: "post",
      url: url + "/api/history-bets",
      headers: {
        Authorization: `Bearer ${this.props.data.token}`
      },
      data: {
        date_from: moment()
          .subtract(1, "months")
          .unix(),
        date_to: moment(new Date()).unix()
      }
    }).then(res => {
      this.setState({
        gamesHist: res.data.data.history
      });
      if (res.data.data.history.length !== 0) {
        let bets = 0;
        let wins = 0;
        res.data.data.history.forEach((item, i) => {
          bets += +item.amount_bet;
          if (item.amount_win !== null) {
            wins += +item.amount_win;
          }
        });
        this.setState({
          bets: bets*100/(bets+wins),
          wins: wins*100/(bets+wins)
        });
      }
      const chartConfig = {
        type: "pie",
        data: {
          datasets: [
            {
              hoverBorderColor: "#ffffff",
              data: [this.state.bets.toFixed(2), this.state.wins.toFixed(2)],
              backgroundColor: ["#fe9c26", "#F25D31"]
            }
          ],
          labels: [i18next.t('Bets, %'), i18next.t('Wins, %')]
        },
        options: this.state.options
      };
      depositWithdraw = new Chart(this.canvasRef2.current, chartConfig);
    });
  };
  componentDidMount() {
    if (this.props.data.token !== null) {
      this.getData();
    }
  }

  render() {
    // const { title } = this.props;
    return (
      <>
        <div className="pies-container">
          <Card small className="h-100">
            <CardBody className="d-flex relative py-0">
              <canvas
                height="220"
                ref={this.canvasRef}
                className="blog-users-by-device m-auto"
              />
            {this.state.transactionsHist.length === 0 &&
              <div className='no-data'>
                {i18next.t("No transactions during this period.")}
              </div>
            }
            </CardBody>
            <CardFooter className="border-top">
              <Row>
                <Col>
                  <FormSelect
                    size="sm"
                    value={this.state.value}
                    style={{ maxWidth: "130px" }}
                    onChange={e => {
                      this.setState({ value: e.target.value });
                      this.changePeriod(e.target.value);
                    }}
                  >
                    <option value="week">{i18next.t('Last Week')}</option>
                    <option value="months">{i18next.t('Last Month')}</option>
                    <option value="years">{i18next.t('Last Year')}</option>
                  </FormSelect>
                </Col>
                <Col className="text-right view-report">
                  {/* eslint-disable-next-line */}
                  <div className="button-to">
                    <Link className="user-menu-link" to={"/user/transactions"}>
                      {i18next.t('Go to history')}
                    </Link>
                  </div>
                </Col>
              </Row>
            </CardFooter>
          </Card>
          <Card small className="h-100">
            <CardBody className="d-flex relative py-0">
              <canvas
                height="220"
                ref={this.canvasRef2}
                className="blog-users-by-device m-auto"
              />
              {this.state.gamesHist.length === 0 &&
                <div className='no-data'>
                  {i18next.t("No games history during this period.")}
                </div>
              }
            </CardBody>
            <CardFooter className="border-top">
              <Row>
                <Col>
                  <FormSelect
                    size="sm"
                    value={this.state.value2}
                    style={{ maxWidth: "130px" }}
                    onChange={e => {
                      this.setState({ value2: e.target.value });
                      this.changePeriod2(e.target.value);
                    }}
                  >
                  <option value="week">{i18next.t('Last Week')}</option>
                  <option value="months">{i18next.t('Last Month')}</option>
                  <option value="years">{i18next.t('Last Year')}</option>
                  </FormSelect>
                </Col>
                <Col className="text-right view-report">
                  {/* eslint-disable-next-line */}
                  <div className="button-to">
                    <Link
                      className="user-menu-link"
                      to={"/user/casino-and-games"}
                    >
                      {i18next.t('Go to history')}
                    </Link>
                  </div>
                </Col>
              </Row>
            </CardFooter>
          </Card>
        </div>
        {
          /*
          <ApexChart topGames={this.state.topGames} />
          */
        }
      </>
    );
  }
}

UsersByDevice.defaultProps = {
  title: i18next.t('Statistics')
};

export default UsersByDevice;
