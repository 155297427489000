/*eslint-disable*/
      const lnk = document.createElement('style'); // delete if not required. used to style the coupon on the mobile version
      lnk.innerText = '#sb-mobile-coupon-external #sb-mobile-menu-external-btn-betslip {--app-external-mobile-menu-background: #495b8d;--app-external-mobile-menu-border-color: #fff;--app-external-mobile-menu-icon-color: #fff;--app-external-mobile-menu-counter-background: #000;--app-external-mobile-menu-counter-color: #fff;}';

      let currentTheme = 'light'; // current user theme
      if (localStorage.getItem("theme") === 'false') {
        currentTheme = 'light';
      } if (localStorage.getItem("theme") === 'true') {
        currentTheme = 'dark';
      }
       // delete if not required
      function addCouponStyles() {
          try {
              document.head.appendChild(lnk);
          } catch (e) {}
      }

       // delete if not required
      function removeCouponStyles() {
          try {
              document.head.removeChild(lnk);
          } catch (e) {}
      }

      function setDarkTheme() {
          api.removeRootClass({className: 'bingobet-theme'});

          // you can use it for a darker theme
          api.setTheme({theme: 'dark'});

          addCouponStyles();  // delete if not required
          currentTheme = 'dark';
      }

      function setLightTheme() {
          api.addRootClass({className: 'bingobet-theme'});

          // you can use it for a darker theme
          api.setTheme({theme: 'default'});

          removeCouponStyles();  // delete if not required
          currentTheme = 'light';
      }

      function switchTheme() {
          if (currentTheme === 'light') {
              setDarkTheme();
          } else {
              setLightTheme();
          }
      }
      function toggleTheme(boo) {
        // console.log(boo);
        if (boo === true) {
          setDarkTheme();
        } if (boo === false) {
          setLightTheme();
        }
      }

      function onSportsbookState(state) {
          switch (state) {
              case SGSportsBook.SBState.LOADING:
                  onSportsbookLoading();
                  break;
          }
      }

      function onSportsbookLoading() {
          if (currentTheme === 'light') {
              setLightTheme();
          } else {
              setDarkTheme();
          }
      }

      // window.ON_SPORTSBOOK_API_LOADED = function() {
      //     window.api = SGSportsBook.SBApi.createClient();
      //     window.api.onEvent(e => {
      //         switch (e.getEvent()) {
      //             case SGSportsBook.SBEvents.STATE: {
      //                 onSportsbookState(e.getParams());
      //                 break;
      //             }
      //         }
      //     });
      //
      // };
      // document.addEventListener("DOMContentLoaded", ready);
      window.onload = function() {
        if (localStorage.getItem("theme") === 'false') {
          setLightTheme();
          console.log(1);
        } if (localStorage.getItem("theme") === 'true') {
          setDarkTheme();
          console.log(2);
        }
    };
      window.switchTheme = switchTheme;
      window.toggleTheme = toggleTheme

export default toggleTheme
