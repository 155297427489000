import React, { useState } from "react";
import {
  IonSelect,
  IonInput,
  IonItem,
  IonToast,
  IonLabel,
  IonSelectOption
} from "@ionic/react";
import axios from "axios";
import url from "../../../axios.js";
import exchangeIcon from "../../../images/icons/exchange.svg";

function TransferMoney(props) {
  const [showToast1, setShowToast1] = useState(false);
  const [toastText, setToastText] = useState(false);
  const [transferFrom, setTransferFrom] = useState();
  const [transfer, setTransfer] = useState("");
  const [transferTo, setTransferTo] = useState();
  const [value, setValue] = useState();
  function submit() {
    if (transferFrom === undefined || transferFrom === "") {
      setTransferFrom("");
    }
    if (transferTo === undefined || transferTo === "") {
      setTransferTo("");
    }
    if (value === undefined || value === "") {
      setValue("");
    }
    if (
      transferTo !== undefined &&
      transferTo !== "" &&
      transferFrom !== undefined &&
      transferFrom !== "" &&
      value !== undefined &&
      value !== ""
    ) {
      props.setShowLoading(true);
      axios({
        method: "post",
        url: url + "/api/transfer-money",
        headers: {
          Authorization: `Bearer ${props.data.token}`
        },
        data: {
          currency_from: transferFrom,
          currency_to: transferTo,
          amount: value
        }
      })
        .then(res => {
          if (res.data.status === 1) {
            props.updateUser(props.data.token);
            setTransferTo(" ");
            setTransferFrom(" ");
            setValue(" ");
            setToastText("Your transfer has been submitted.");
            setShowToast1(true);
            props.setShowLoading(false);
          }

          // props.setShowLoading(false);
          // setShowToast1(true)
        })
        .catch(error => {
          /*422*/
          props.setShowLoading(false);
        });
    }
  }
  function pickCurrency(currency_id) {
    if (currency_id === 840) {
      return "$";
    }
    if (currency_id === 978) {
      return "€";
    }
    if (currency_id === 643) {
      return "₽";
    }
    if (currency_id === 826) {
      return "£";
    }
  }
  function getCurrency(currency_id) {
    if (currency_id === 840) {
      return "$";
    }
    if (currency_id === 978) {
      return "€";
    }
    if (currency_id === 643) {
      return "₽";
    }
    if (currency_id === 826) {
      return "£";
    }
  }
  function checkTotal(e) {
    if (transferFrom === undefined || transferFrom === "") {
      setTransferFrom("");
    }
    if (transferTo === undefined || transferTo === "") {
      setTransferTo("");
    }
    if (
      transferTo !== undefined &&
      transferTo !== "" &&
      transferFrom !== undefined &&
      transferFrom !== "" &&
      e !== undefined &&
      e !== ""
    ) {
      setValue(e);
      axios({
        method: "post",
        url: url + "/api/calculate-transfer",
        headers: {
          Authorization: `Bearer ${props.data.token}`
        },
        data: {
          currency_from: transferFrom,
          currency_to: transferTo,
          amount: e
        }
      })
        .then(res => {
          // props.updateUser(props.data.token)
          if (res.data.status === 1) {
            setTransfer(res.data.data.currencyRates);
          }
        })
        .catch(error => {
          /*422*/
          setTransfer("");
        });
    }
  }
  return (
    <div className="transfer-money">
      <div className="shade">
        <p className="top-shade">
          <img alt="exchange" src={exchangeIcon} className="deposit-icon" />
          <span>Exchange</span>
        </p>
        <p className="bottom-shade">Money</p>
      </div>
      <p className="title">
        <span>Exchange Money</span>
      </p>
      {props.data.balance.map((el, i) => {
        return (
          <p key={i} className="deposit">
            Deposit in {getCurrency(el.currency_id)}{" "}
            <span>
              {el.ob}
              {pickCurrency(el.currency_id)}
            </span>
          </p>
        );
      })}
      <div className="transfer-container">
        <p className="transfer-from">Transfer From</p>
        <IonSelect
          onIonChange={e => setTransferFrom(e.detail.value)}
          placeholder="Choose deposit"
          value={transferFrom}
          className={
            "header-search gender deposit-field " +
            (transferFrom !== undefined && transferFrom === "" ? "wrong" : "")
          }
          interface="popover"
        >
          {props.data.balance.map((el, i) => {
            return (
              <IonSelectOption key={i} value={el.currency_id}>
                {el.ob} {getCurrency(el.currency_id)}
              </IonSelectOption>
            );
          })}
        </IonSelect>
      </div>
      <div className="transfer-container">
        <p className="transfer-from">Transfer To</p>
        <IonSelect
          onIonChange={e => setTransferTo(e.detail.value)}
          placeholder="Choose deposit"
          value={transferTo}
          className={
            "header-search gender deposit-field " +
            (transferTo !== undefined && transferTo === "" ? "wrong" : "")
          }
          interface="popover"
        >
          {props.data.currencies.map((el, i) => {
            return (
              <IonSelectOption key={i} value={el.id}>
                Deposit in {getCurrency(el.id)}
              </IonSelectOption>
            );
          })}
        </IonSelect>
      </div>
      <div className="transfer-container">
        <p className="transfer-from">
          Amount{transferTo !== undefined && transferTo !== "" && ","}{" "}
          {getCurrency(transferFrom)}
        </p>
        <IonInput
          className={
            "header-search deposit-field " +
            (value !== undefined && value === "" ? "wrong" : "")
          }
          type="text"
          value={value}
          placeholder=""
          onIonChange={e => checkTotal(e.detail.value)}
        />
      </div>
      {transfer !== "" && (
        <p className="transfer-from total-amount">
          The total transfer will be {transfer} {getCurrency(transferTo)}
        </p>
      )}
      <IonItem
        button
        onClick={() => submit()}
        className="register-btn submit transfer-submit"
      >
        <IonLabel>Submit</IonLabel>
      </IonItem>
      <IonToast
        isOpen={showToast1}
        onDidDismiss={() => setShowToast1(false)}
        message={toastText}
        color="primary"
        duration={10000}
      />
    </div>
  );
}

export default TransferMoney;
