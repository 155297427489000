import React,{useState, useRef, useEffect} from 'react';
import './css/chat.css';
import useSound from 'use-sound';
import sfx from './sounds/message.mp3';
import ChatInput from './components/Input.js';
import Message from './components/Message.js';
import Unlogged from './components/Unlogged.js';
import {ReactComponent as CloseLogo} from './icons/cancel.svg';
import {ReactComponent as ChatHidden} from './icons/conversation.svg';
import moment from 'moment';
import * as functions from './functions';


const Chat = (props) => {
  const messagesEndRef = useRef(null);
  const [chatOpen, setChatOpen] = useState(false);
  const [chatId, setChatId] = useState(0);
  const [userName, setUserName] = useState('');
  const [messages, setMessages] = useState([]);
  const [greeting] = useState([{
    isUser: false,
    message: 'Hello! Is there anything we can do for you?',
    time: moment(Date.now()).format('HH:mm')
  }]);
  const [playOn] = useSound(sfx,{ volume: 0.5 });
  function scrollToBottom(){
    messagesEndRef.current.scrollIntoView({ behavior: "smooth" });
  }
  /*user greeting*/
  useEffect(() => {
    if (userName !== '') {
      playOn();
      getChatId(userName);
      let timer = setInterval(() => getMessages(chatId), 30000);
    }
  },[userName]);

  useEffect(() => {
    if (messages.length > 1) {
      scrollToBottom()
    }
  }, [messages])
  //временно
  function addMine(text) {
    setMessages([...messages, {
      isUser: true,
      message: text,
      time: moment(Date.now()).format('HH:mm')
    }]);
    scrollToBottom();
    // playOn();
    sendMessage(chatId, text)
  }
  function getChatId(name) {
    functions.getChatId(name)
      .then(res => {
        setChatId(res.data.data.chat_id);
        getMessages(res.data.data.chat_id)
      });
  }
  function getMessages(id) {
    functions.getMessages(id)
    .then(res => {
      if (res.data.data !== null) {
        let msgs = res.data.data[0].messages.map(el => {
          if (el.in === 0) {
            return {
              isUser: true,
              message: el.text,
              time: moment(el.time).format('HH:mm')
            }
          } else {
            return {
              isUser: false,
              message: el.text,
              time: moment(el.time).format('HH:mm')
            }
          }
        });
        if (msgs.length > messages.length) {
          playOn();
        }
        setMessages([...msgs]);
      }
    });
  }
  function sendMessage(chatId, message) {
    functions.sendMessage(chatId, message).then(() => getMessages(chatId))
  }
  return (
    <div className={"chat-global " }>
      <div className={"chat-window " + (chatOpen === true ? '' : 'closed')}>

        <div className='chat-header'>
           {userName !== '' && <p>Welcome, {userName}!</p>}
           {userName === '' && <p>Chat support</p>}
          <div onClick={() => setChatOpen(!chatOpen)} className="close">
            <CloseLogo/>
          </div>
        </div>
        {/*logged user*/}
        { userName !== '' &&
          <div
            className='chat-body'>
            {greeting.map((el,i) => {
              return (
                <Message key={i} data={el}/>
              )
            })}
            {
              messages.map((el,i) => {
                return (
                  <Message key={i} data={el}/>
                )
              })
            }
            <div ref={messagesEndRef} />
          </div>
        }

        {userName !== '' && <ChatInput addMine={addMine}/>}

        {props.token === null && userName === '' && <Unlogged setUserName={setUserName}/>}

      </div>

      <div onClick={() => setChatOpen(!chatOpen)} className={'chat-hidden ' + (chatOpen ? '' : 'active')}>
        <ChatHidden/>
      </div>

    </div>
  )
}

export default Chat
