import React, { useState } from "react";
import { IonButton, IonModal, IonInput, IonToast } from "@ionic/react";
import axios from "axios";
import url from "../axios.js";
import { useTranslation } from "react-i18next";
import RestoreModal from "./RestoreModal.js";

import {  useHistory } from "react-router-dom";


const LoginModal = props => {
  let history = useHistory();
  const { t } = useTranslation();
  const [loginError, setLoginError] = useState(false);
  const [loginErrorMessage, setLoginErrorMessage] = useState(false);
  const [username, setUsername] = useState();
  const [restore, setRestore] = useState(false);
  const [password, setPassword] = useState();
  function closeAll() {
    props.setShowLoginModal(false)
  }
  function login() {
    axios.interceptors.response.use(
      response => {
        return response;
      },
      function(error, response) {
        if (error.response.status === 422 || error.response.status === 403) {
          setLoginErrorMessage(t("Incorrect login or password"));
          setLoginError(true);
          if (
            !Array.from(
              document.querySelectorAll(".modal-container")[0].classList
            ).includes("incorrect")
          ) {
            document
              .querySelectorAll(".modal-container")[0]
              .classList.toggle("incorrect");
          }
        }
        props.setShowLoading(false);
        return Promise.reject(error);
      }
    );
    const uuidv4 = require("uuid/v4");
    var uuid = uuidv4();

    setLoginErrorMessage("");
    setLoginError(false);
    props.setShowLoading(true);
    axios({
      method: "post",
      url: url + "/api/login",
      data: {
        password: password,
        login: username,
        uuid: uuid
      }
    }).then(res => {
      if (res.data.data.token) {

        props.setToken(res.data.data.token, uuid);
        // console.log(props);
        // props.data.loginModal === true ? props.setMainLogin(false) : props.setMainLogin(true);
        setUsername("");
        setPassword("");
        // props.setUuid(uuid);
        props.setShowLoginModal(false);
        props.setShowLoading(false);
        history.push("/home");
      }
      return res.data;
    });

  }
  return (
    <IonModal
      mode={"md"}
      onDidDismiss={() => props.setShowLoginModal(false)}
      isOpen={props.showLoginModal}
      cssClass="login-modal"
    >
      <div className="modal-container">
        <div
          className="modal-close"
          onClick={() => props.setShowLoginModal(false)}
        />
        <div className="big-logo" />
        <IonInput
          value={username}
          onIonChange={e => setUsername(e.detail.value)}
          placeholder={t("login")}
          className="modal-input"
        />
        <IonInput
          value={password}
          onIonChange={e => setPassword(e.detail.value)}
          placeholder={t("Password")}
          type="password"
          className="modal-input"
        />
        <IonButton onClick={() => login()} className="modal-button">
          {t("Login")}
        </IonButton>
        <p className={'res-link'} onClick={() => {setRestore(true) }} >{t("Restore password")}</p>
      </div>
      <IonToast
        isOpen={loginError}
        onDidDismiss={() => {
          setLoginError(false);
        }}
        message={loginErrorMessage}
        color="danger"
        duration={5000}
      />
    <RestoreModal isOpen={restore} setOpen={setRestore} closeAll={closeAll}/>
    </IonModal>
  );
};

export default LoginModal;
