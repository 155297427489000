import { IonInput, IonContent,IonToast, IonPage,IonCheckbox,IonSelect,IonSelectOption } from '@ionic/react';
import { Redirect} from 'react-router-dom';
import { Link } from "react-router-dom";
import { useAtom } from "jotai";
import { promo } from "../state.js";
import React, { useState, useEffect } from 'react';
import { useHistory,useLocation } from 'react-router-dom';
// import "bootstrap/dist/css/bootstrap.min.css";
import  Header  from '../components/Header.js';
import  Footer  from '../components/Footer.js';
import '../css/register/Register.css';
import '../css/register/Media.css';
import moment from 'moment';
import axios from 'axios';
import ReactFlagsSelect from 'react-flags-select';
import 'react-flags-select/css/react-flags-select.css';
import url from '../axios.js';
import { useTranslation } from "react-i18next";

function Register(props){
  let history = useHistory();
  const location = useLocation();
  const { t } = useTranslation();
  const [promocode, setPromocode] = useAtom(promo);
  const [customPromo, setCustomPromo] = useState('');
  const [showToast1, setShowToast1] = useState(false);
  const [toastText, setToastText] = useState();
  const [permittedCountries, setPermittedCountries] = useState(props.data.countries);
  const [registered, setRegistered] = useState(false);
  const [usernameInUse, setUsernameInUse] = useState(false);
  const [emailInUse, setEmailInUse] = useState(false);
  const [username, setUsername] = useState();
  const [emailFailure, setEmailFailure] = useState(t("This email is already in use"));
  const [email, setEmail] = useState();
  const [password, setPassword] = useState();
  const [confirmPassword, setConfirmPassword] = useState();
  const [firstName, setFirstName] = useState();
  const [lastName, setLastName] = useState();
  const [gender, setGender] = useState('mr');
  const [day, setDay] = useState();
  const [year, setYear] = useState();
  const [month, setMonth] = useState();
  const [country, setCountry] = useState();
  const [addressLine1, setAddressLine1] = useState();
  const [addressLine2, setAddressLine2] = useState('');
  const [city, setCity] = useState();
  const [postcode, setPostcode] = useState();
  const [stateRegion, setStateRegion] = useState();
  const [mobile, setMobile] = useState();
  const [mobileCode, setMobileCode] = useState();
  const [monthly, setMonthly] = useState();
  const [weekly, setWeekly] = useState();
  const [daily, setDaily] = useState();
  const [customMonthly, setCustomMonthly] = useState();
  const [customWeekly, setCustomWeekly] = useState();
  const [customDaily, setCustomDaily] = useState();
  const [language, setLanguage] = useState('GB');
  const [currency, setCurrency] = useState();
  const [checked1, setChecked1] = useState(0);
  const [checked2, setChecked2] = useState(0);
  const [checked3, setChecked3] = useState(false);
  const check = /^(?=.*[0-9])(?=.*[A-z])(?=\S+$).{5,}$/;
  const checkWeak = /^(?=.*[0-9])(?=\S+$).{4,}$/;

  useEffect(() => {
     setPermittedCountries(props.data.countries);
 }, [props.data.countries]);
  // axios.interceptors.request.use(request => {
  //          console.log(request);
  //      return request;
  //
  //  });
  // let location = useLocation();
 //  useEffect(() => {
 //     if (location.pathname.includes('register') && !location.pathname.includes('demo')) {
 //       document.getElementsByClassName('sticky')[0].classList.add('hide-mobile')
 //     }
 //
 //     if (location.pathname.includes('register')) {
 //       scroll('.login-modal.register')
 //     } else {
 //       document.getElementsByClassName('sticky')[0].classList.remove('hide-mobile')
 //     }
 // }, [location.pathname]);
   function checkNick() {
     if (username !== undefined && username !== '') {
       axios({
             method: 'post',
             url: url + '/api/checkusername',
             data: {
               login: username
             }
           })
           .then(res => {
              setUsernameInUse(false);
            }).catch(error => {
              /*422*/
              setUsernameInUse(true);
             })
     }
   }
   function checkEmail() {
     if (email !== undefined && email !== '') {
       axios({
             method: 'post',
             url: url + '/api/checkemail',
             data: {
               email: email
             }
           })
           .then(res => {
              setEmailInUse(false);
            }).catch((error) => {
              /*422*/
              setEmailFailure(error.response.data.error[0])
              setEmailInUse(true);
             })
     }
   }
  function firstStep() {
      if (email !== undefined &&
          email !== '' &&
         username !== undefined &&
         username !== '' &&
         password !== undefined &&
         password !== '' &&
         confirmPassword !== undefined &&
         confirmPassword !== '' &&
         password === confirmPassword &&
         usernameInUse === false &&
         emailInUse === false) {
            openStep("first-step-hideable");
            openStep("second-step-hideable");

      }  if (email === undefined || email === '') {
          setEmail('')
      }  if (password === undefined || password === "") {
          setPassword('')
      }  if (confirmPassword === undefined || confirmPassword === "") {
          setConfirmPassword('')
      }  if (username === undefined || username === "") {
          setUsername('')
      } if (document.getElementsByClassName('wrong')[0]) {
         document.getElementsByClassName('wrong')[0].scrollIntoView({block: "center", behavior: "smooth"});
       }
  }
  function secondStep() {
    if (gender === undefined || gender === "") {
        setGender('')
    } if (firstName === undefined || firstName === "") {
        setFirstName('')
    } if (lastName === undefined || lastName === "") {
        setLastName('')
    } if (day === undefined || day === "") {
        setDay('')
    } if (year === undefined || year === "") {
        setYear('')
    } if (month === undefined || month === "") {
        setMonth('')
    } if (country === undefined || country === "") {
        setCountry('')
    } if (document.getElementsByClassName('wrong')[0]) {
       document.getElementsByClassName('wrong')[0].scrollIntoView({block: "center", behavior: "smooth"});
     } if (gender !== undefined &&
         gender !== '' &&
        firstName !== undefined &&
        firstName !== '' &&
        lastName !== undefined &&
        lastName !== '' &&
        day !== undefined &&
        day !== '' &&
        year !== undefined &&
        year !== '' &&
        month !== undefined &&
        month !== '' &&
        country !== undefined &&
        country !== '') {
          openStep("second-step-hideable");
          openStep("third-step-hideable");
      }
  }
  function thirdStep() {
    if (addressLine1 === undefined || addressLine1 === "") {
       setAddressLine1('');
   } if (city === undefined || city === "") {
      setCity('');
   } if (postcode === undefined || postcode === "") {
      setPostcode('');
   } if (stateRegion === undefined || stateRegion === "") {
      setStateRegion('');
   } if (mobile === undefined || mobile === "") {
      setMobile('');
   } if (mobileCode === undefined || mobileCode === "") {
      setMobileCode('');
   } if (monthly === undefined || monthly === "") {
      setMonthly('');
   } if (daily === undefined || daily === "") {
      setDaily('');
   } if (weekly === undefined || weekly === "") {
      setWeekly('');
   } if (currency === undefined || currency === "") {
      setCurrency('');
   } if (checked2 === false || checked2 === "") {
      setChecked2(false);
   }if (addressLine1 !== undefined &&
        addressLine1 !== '' &&
        city !== undefined &&
        city !== '' &&
        postcode !== undefined &&
        postcode !== '' &&
        stateRegion !== undefined &&
        stateRegion !== '' &&
        mobile !== undefined &&
        mobile !== '' &&
        mobileCode !== undefined &&
        mobileCode !== '' &&
        monthly !== undefined &&
        monthly !== '' &&
        daily !== undefined &&
        daily !== '' &&
        weekly !== undefined &&
        weekly !== '' &&
        checked2 !== undefined &&
        checked2 !== false &&
        checked1 !== undefined &&
        checked1 !== false  ) {
          props.setShowLoading(true);
        let data = {
            login: username,
            password: password,
            email: email,
            title: gender,
            first_name: firstName,
            last_name: lastName,
            country: country,
            address_line: addressLine1,
            city: city,
            postcode: postcode,
            state: stateRegion,
            phone: mobile,
            country_code: mobileCode,
            language: language,
            currency_id: 978,
            promo: promocode !== '' ? promocode : customPromo,
            subscription: checked3
          }
          addressLine2 === '' ? console.log('no addressLine2') : data.address_line2 = addressLine2;
          let temporaryDate = moment( month + "/" + day + "/" +  year).format('L');
          data.birth_date = moment(temporaryDate).unix();
          daily === 'custom' ? data.day_limit = customDaily : data.day_limit = daily.toString();
          weekly === 'custom' ? data.week_limit = customWeekly : data.week_limit = weekly.toString();
          monthly === 'custom' ? data.month_limit = customMonthly : data.month_limit = monthly.toString();
          axios({
                method: 'post',
                url: url + '/api/register',
                data: data
              })
              .then(res => {
                 // props.setToken(res.data.data.token, null);
                 // setRegistered(true);
                 props.setShowLoading(false);
                 history.push('/home');
               })
        }
        if (document.getElementsByClassName('wrong')[0]) {
          document.getElementsByClassName('wrong')[0].scrollIntoView({block: "center", behavior: "smooth"});
        }

  }
  const yearFor = (new Date()).getFullYear();
  const years = Array.from(new Array(80),( val, index) => yearFor - index);
  const dates = Array.from(new Array(31),( val, index) =>  ++index);
  const limits = [10,20,30,40,50,60,70,80];
  var months = ["January","February","March","April","May","June","July",
            "August","September","October","November","December"];
  function openStep(step) {
    document.getElementsByClassName(step)[0].classList.toggle('open');
  }
  let checkDaily = (e) => {
    setDaily(e);
  };
  let checkWeekly = (e) => {
    setWeekly(e);
  }
  let checkMonthly = (e) => {
    setMonthly(e);
  }
  return (
    <IonPage>
      <IonContent mode={'md'} scrollEvents={true}>

        <Header
          theme={props.theme}
          setTheme={props.setTheme}
          setMainLogin={props.setMainLogin}
          changeAppLang={props.changeAppLang}
          toggle = {props.toggle}
          openGame={props.openGame}
          getInfo={props.getInfo}
          toggleMode={props.toggleMode}
          setUuid={props.setUuid}
          clearState={props.clearState}
          setShowLoading={props.setShowLoading}
          setToken={props.setToken}
          page={'Профиль'}
          data={props.data}
          />

          <div className='login-modal register'>
            <div className="modal-container-title unselectable">
            {t("Create")} <div className="modal-container-title-second unselectable">{t("Account")}</div>
            </div>
            <div className="modal-container">
              <div className="first-step step">
                <p onClick={() => openStep('first-step-hideable')} className='unselectable step-title'>{t("Account Information")}</p>
                <div className="first-step-hideable open">
                  <div className='step-input-container'>
                    <p className='step-input-title'>{t("Username")}</p>
                    <IonInput
                      className={'header-search ' + (username !== undefined && username === '' ? "wrong" : '') + (usernameInUse === true ? "wrong" : '')}
                      type="text"
                      autoComplete={'off'}
                      value={username}
                      placeholder={t("Username")}
                      onBlur={() => checkNick()}
                      onIonChange={e => setUsername(e.detail.value)}></IonInput>
                  </div>
                  {
                    usernameInUse === true &&
                    <p className='subtitle subtitle-alert'>{t("This username is already in use")}</p>
                  }
                  <div className='step-input-container'>
                    <p className='step-input-title'>{t("Email")}</p>
                    <IonInput
                      className={'header-search ' + (email !== undefined && email === '' ? "wrong" : '') + ( emailInUse === true ? "wrong" : '')}
                      type="email"
                      value={email}
                      autocomplete={'off'}
                      placeholder="example@domain.com"
                      onBlur={() => checkEmail()}
                      onIonChange={e => setEmail(e.detail.value)}>
                    </IonInput>
                  </div>
                  {
                    emailInUse === true &&
                    <p className='subtitle subtitle-alert'>{emailFailure}</p>
                  }
                  <div className='step-input-container'>
                    <p className='step-input-title'>{t("Password")}</p>
                    <IonInput
                      className={'header-search ' + (password !== undefined && password === '' ? "wrong" : '')}
                      type="password"
                      value={password}
                      autocomplete={'off'}
                      placeholder={t("Password")}
                      onIonChange={e => setPassword(e.detail.value)}>
                    </IonInput>
                  </div>
                  {
                    password !== undefined && password !== '' && checkWeak.test(password) < 6 && !check.test(password) &&
                    <p className='subtitle'>{t("Password Weak")}</p>
                  }

                  {
                    password !== undefined && password !== '' && check.test(password) &&
                    <p className='subtitle'>{t("Password Strong")}</p>
                  }

                  <div className='step-input-container'>
                    <p className='step-input-title'>{t("Confirm Password")}</p>
                    <IonInput
                      className={'header-search ' + (confirmPassword !== undefined && confirmPassword === '' ? "wrong" : '')}
                      type="password"
                      autoComplete={'off'}
                      value={confirmPassword}
                      placeholder={t("Confirm Password")}
                      onIonChange={e => setConfirmPassword(e.detail.value)}>
                    </IonInput>
                  </div>
                  {
                    confirmPassword !== password &&
                    <p className='subtitle subtitle-alert'>{t("Please make sure your passwords match.")}</p>
                  }
                  {promocode !== '' && <div className='step-input-container'>
                    <p className='step-input-title'>{t("Promo code")}</p>
                    <IonInput
                      className={'header-search ' + (confirmPassword !== undefined && confirmPassword === '' ? "wrong" : '')}
                      type="text"
                      disabled={true}
                      value={promocode}
                      placeholder={t("Promo code")}
                      onIonChange={e => setPromocode(e.detail.value)}>
                    </IonInput>
                  </div>}
                  {promocode === '' && <div className='step-input-container'>
                    <p className='step-input-title'>{t("Promo code")}</p>
                    <IonInput
                      className={'header-search '}
                      type="text"
                      autoComplete={'off'}
                      value={customPromo}
                      placeholder={t("Promo code")}
                      onIonChange={e => setCustomPromo(e.detail.value)}>
                    </IonInput>
                  </div>}
                  <p onClick={() => firstStep()} className="next-step unselectable">{t("Next")} ></p>
                </div>
              </div>

              {/*second*/}
              <div className="second-step step">
                <p onClick={() => openStep('second-step-hideable')} className='unselectable step-title'>{t("Personal Details")}</p>
                <div className="second-step-hideable">
                  <div className='step-input-container'>
                    <p className='step-input-title'>{t("Title")}</p>
                    <IonSelect
                    onIonChange={e => setGender(e.detail.value)}
                    placeholder={t("Mr.")} value={gender}
                    className={'header-search gender ' + (gender !== undefined && gender === '' ? "wrong" : '')}
                    interface="popover">
                      <IonSelectOption value="mr">{t("Mr.")}</IonSelectOption>
                      <IonSelectOption value="ms">{t("Ms.")}</IonSelectOption>
                    </IonSelect>
                  </div>
                  <div className='step-input-container'>
                    <p className='step-input-title'>{t("First Name")}</p>
                    <IonInput
                      className={'header-search ' + (firstName !== undefined && firstName === '' ? "wrong" : '')}
                      type="text"
                      value={firstName}
                      placeholder={t("First Name")}
                      onIonChange={e => setFirstName(e.detail.value)}>
                    </IonInput>
                  </div>
                  <div className='step-input-container'>
                    <p className='step-input-title'>{t("Last Name")}</p>
                    <IonInput
                      className={'header-search '+ (lastName !== undefined && lastName === '' ? "wrong" : '')}
                      type="text"
                      autoComplete={'off'}
                      value={lastName}
                      placeholder={t("Last Name")}
                      onIonChange={e => setLastName(e.detail.value)}>
                    </IonInput>
                  </div>
                  <div className='step-input-container'>
                    <p className='step-input-title'>{t("Date of Birth")}</p>
                    <div className='dates'>
                      <IonSelect
                        onIonChange={e => setYear(e.detail.value)}
                        value={year}
                        placeholder="YYYY"
                        className={'header-search date ' + (year !== undefined && year === '' ? "wrong" : '')}
                        interface="popover">
                        {years.map(el => {
                          return(
                          <IonSelectOption key={el} value={el}>{el}</IonSelectOption>
                        )
                        })}

                      </IonSelect>
                      <IonSelect
                        onIonChange={e => setMonth(e.detail.value)}
                        value={month}
                        placeholder="MM"
                        className={'header-search date ' + (month !== undefined && month === '' ? "wrong" : '')}
                        interface="popover">
                        {months.map((el, i) => {
                          return(
                          <IonSelectOption key={el} value={'0' + ++i }>{el}</IonSelectOption>)
                        })}
                      </IonSelect>
                      <IonSelect
                        onIonChange={e => setDay(e.detail.value)}
                        value={day}
                        placeholder="DD"
                        className={'header-search date ' + (day !== undefined && day === '' ? "wrong" : '')}
                        interface="popover">
                        {dates.map(el => {
                          return(
                          <IonSelectOption key={el} value={el}>{el}</IonSelectOption>)
                        })}
                      </IonSelect>
                    </div>
                  </div>
                  <div className='step-input-container'>
                    <p className='step-input-title'>{t("Country")}</p>
                    <ReactFlagsSelect
                      className={'header-search country ' + (country !== undefined && country === '' ? "wrong" : '')}
                     searchable={true}
                     onSelect={e => setCountry(e)}
                     defaultCountry=""
                     placeholder={t("Search for a country")}
                     countries={permittedCountries}
                     blackList={true}
                     searchPlaceholder={t("Search for a country")} />
                  </div>
                  <p onClick={() => secondStep()}  className="next-step unselectable">{t("Next")} ></p>
                </div>
              </div>
              {/*third*/}
              <div className="third-step step">
                <p onClick={() => openStep('third-step-hideable')} className='unselectable step-title'>{t("Contact Details")}</p>
                <div className="third-step-hideable">
                  <div className='step-input-container'>
                    <p className='step-input-title'>{t("Address Line 1")}</p>
                    <IonInput
                      className={'header-search ' + (addressLine1 !== undefined && addressLine1 === '' ? "wrong" : '')}
                      type="text"
                      value={addressLine1}
                      placeholder={t("Address Line 1")}
                      onIonChange={e => setAddressLine1(e.detail.value)}></IonInput>
                  </div>
                  <div className='step-input-container'>
                    <p className='step-input-title'>{t("Address Line 2")}</p>
                    <IonInput
                      className={'header-search '}
                      type="text"
                      value={addressLine2}
                      placeholder={t("Address Line 2")}
                      onIonChange={e => setAddressLine2(e.detail.value)}></IonInput>
                  </div>
                  <div className='step-input-container'>
                    <p className='step-input-title'>{t("City")}</p>
                    <IonInput
                      className={'header-search ' + (city !== undefined && city === '' ? "wrong" : '')}
                      type="text"
                      value={city}
                      placeholder={t("City")}
                      onIonChange={e => setCity(e.detail.value)}>
                    </IonInput>
                  </div>
                  <div className='step-input-container'>
                    <p className='step-input-title'>{t("Postcode")}</p>
                    <IonInput
                      className={'header-search ' + (postcode !== undefined && postcode === '' ? "wrong" : '')}
                      type="text"
                      value={postcode}
                      placeholder={t("Postcode")}
                      onIonChange={e => setPostcode(e.detail.value)}>
                    </IonInput>
                  </div>
                  <div className='step-input-container'>
                    <p className='step-input-title'>{t("State/Region")}</p>
                    <IonInput
                      className={'header-search ' + (stateRegion !== undefined && stateRegion === '' ? "wrong" : '')}
                      type="text"
                      value={stateRegion}
                      placeholder={t("State/Region")}
                      onIonChange={e => setStateRegion(e.detail.value)}>
                    </IonInput>
                  </div>
                  <div className='step-input-container'>
                    <p className='step-input-title'>{t("Mobile")}</p>
                    <IonInput
                      className={'header-search mobilecode ' + (mobileCode !== undefined && mobileCode === '' ? "wrong" : '')}
                      type="tel"
                      value={mobileCode}
                      placeholder={t("Country code")}
                      onIonChange={e => setMobileCode(e.detail.value)}>
                    </IonInput>
                    <IonInput
                      className={'header-search mobile ' + (mobile !== undefined && mobile === '' ? "wrong" : '')}
                      type="tel"
                      value={mobile}
                      placeholder={t("Mobile")}
                      onIonChange={e => setMobile(e.detail.value)}>
                    </IonInput>
                  </div>
                  <hr></hr>
                  <p className="comment">{t("Below you can set your deposit limits. These are optional and can be changed later under the Responsible Gaming section. Your deposit limit may be subject to a review by Betway")}</p>
                  <div className='step-input-container'>
                    <p className='step-input-title'>{t("Daily Limit")}</p>
                    <div className='custom-block'>
                      <IonSelect lines="none"
                      onIonChange={e => checkDaily(e.detail.value)}
                      value={daily} placeholder={t("Not Specified")}
                      className={'header-search custom-input ' + (daily !== undefined && daily === '' ? "wrong" : '')}
                      interface="popover">
                        {limits.map(el => {
                          return(
                          <IonSelectOption lines="none" key={el} value={el}>{el}</IonSelectOption>)
                        })}
                        <IonSelectOption className='hide' value={"custom"}>{t("Custom Limit")}</IonSelectOption>
                      </IonSelect>
                      {
                        daily === 'custom' &&
                          <IonInput
                            className={'header-search mobilecode custom-limit '}
                            type="tel"
                            value={customDaily}
                            placeholder={t("Custom Limit")}
                            onIonChange={e => setCustomDaily(e.detail.value)}>
                          </IonInput>
                      }
                    </div>
                  </div>
                  <div className='step-input-container'>
                    <p className='step-input-title'>{t("Weekly Limit")}</p>
                    <div className='custom-block'>
                      <IonSelect
                      lines="none" onIonChange={e => checkWeekly(e.detail.value)}
                      value={weekly}
                      placeholder={t("Not Specified")}
                      className={'header-search custom-input ' + (weekly !== undefined && weekly === '' ? "wrong" : '')}
                      interface="popover">
                        {limits.map(el => {
                          return(
                          <IonSelectOption lines="none" key={el} value={el}>{el}</IonSelectOption>)
                        })}
                        <IonSelectOption className='hide' value={"custom"}>{t("Custom Limit")}</IonSelectOption>
                      </IonSelect>
                      {
                        weekly === 'custom' &&
                          <IonInput
                            className={'header-search mobilecode custom-limit'}
                            type="tel"
                            value={customWeekly}
                            placeholder={t("Custom Limit")}
                            onIonChange={e => setCustomWeekly(e.detail.value)}>
                          </IonInput>
                      }
                    </div>
                  </div>
                  <div className='step-input-container'>
                    <p className='step-input-title'>{t("Monthly Limit")}</p>
                    <div className='custom-block'>
                      <IonSelect lines="none"
                      onIonChange={e => checkMonthly(e.detail.value)}
                      value={monthly}
                      placeholder={t("Not Specified")}
                      className={'header-search custom-input ' + (monthly !== undefined && monthly === '' ? "wrong" : '')}
                      interface="popover">
                        {limits.map(el => {
                          return(
                          <IonSelectOption lines="none" key={el} value={el}>{el}</IonSelectOption>)
                        })}
                        <IonSelectOption className='hide' value={"custom"}>{t("Custom Limit")}</IonSelectOption>
                      </IonSelect>
                      {
                        monthly === 'custom' &&
                          <IonInput
                            className={'header-search mobilecode custom-limit '}
                            type="tel"
                            value={customMonthly}
                            placeholder={t("Custom Limit")}
                            onIonChange={e => setCustomMonthly(e.detail.value)}>
                          </IonInput>
                      }
                    </div>
                  </div>
                  <div className='step-input-container'>
                    <p className='step-input-title'>{t("Preferred Language")}</p>
                    <ReactFlagsSelect
                      className={'header-search country '+ (language !== undefined && language === '' ? "wrong" : '')}
                      defaultCountry="GB"
                      customLabels={{"GB": "English", "ES": "Spanish","IT": "Italian","DE": "German","NL": 'Dutch',"PL": "Polish", "PT": "Portuguese", "RU": "Russian", "TR": "Turkish", "FR": "French", "CN": "Chinese","JP": "Japanese", "AE": "Arabic"}}
                      countries={["GB","ES", "IT", "DE", "NL", "PL", "PT", "RU", "TR", "FR", "CN", "JP", "AE"]}
                     onSelect={e => setLanguage(e)} />

                  </div>
                  <div className="with-checkbox first">
                    <IonCheckbox checked={checked1} className={ (checked1 === false ? "wrong" : '')}  onIonChange={e => setChecked1(e.detail.checked)}></IonCheckbox>
                    <p className='with-checkbox-p'>{t("I accept the ")}<Link to={'/'} target="_blank">{t("Data Protection Policy ,")}</Link> <Link to={'./documents/cookie-policy'} target="_blank">{t("Cookie Policy ,")}</Link> <Link to={'./documents/disclaimer'} target="_blank">{t("Disclaimer")}</Link>, <Link to={'/terms'}  target="_blank">{t("Welcome Bonus Terms and Conditions")}</Link></p>
                  </div>
                  <div className="with-checkbox">
                    <IonCheckbox checked={checked2} className={ (checked2 === false ? "wrong" : '')} onIonChange={e => setChecked2(e.detail.checked)}></IonCheckbox>
                    <p className='with-checkbox-p'>{t("I am of legal age to gamble and I accept the ")}<Link to={'/terms'} target="_blank">{t("Terms and Conditions, ")}</Link> <Link to={'./documents/responsible-policy'} target="_blank">{t("Responsible Gambling policy ,")}</Link> <Link to={'./documents/contributions'} target="_blank">{t("Contributions ,")}</Link>{t(" and ")}<Link to={'./documents/kyc-policy'} target="_blank">{t("KYC Policy")}</Link>.</p>
                  </div>
                  <div className="with-checkbox first">
                    <IonCheckbox checked={checked3} onIonChange={e => setChecked3(e.detail.checked)}></IonCheckbox>
                    <p className='with-checkbox-p'><strong>{t("Don’t miss")}</strong>{t(" the ")}<strong>{t("latest promotions")}</strong>{t(" and ")}<strong>{t("exclusive offers")}</strong>{t(" from ")}<strong>{t("Bingo Bet")}</strong>{t(", and the brands managed by ")}<strong>{t("Ridotto entertainment")}</strong>. {t("Choose how you’d like to receive communications from us.")}</p>
                  </div>
                  <div className="with-checkbox">
                  <p onClick={() => thirdStep()} className="next-step unselectable register-button">{t("Register")} ></p>
                  </div>
                </div>
              </div>
              <p className='annotation'>{t("This site is protected by reCAPTCHA and the Google ")} <Link to={'./documents/policy'} target="_blank">{t("Privacy Policy")}</Link>{t(" and ")}<Link to={'./documents/terms'}  target="_blank">{t("Terms of Service")}</Link>{t(" apply")}.</p>

            </div>
            <div className='register-img'></div>
          </div>
          <IonToast
            isOpen={showToast1}
            onDidDismiss={() => setShowToast1(false)}
            message={toastText}
            color='danger'
            duration={10000}
          />
        {location.pathname.includes('/register') && <Footer />}
        </IonContent>
        {registered === true  &&
          <Redirect to="/"/>
        }

      </IonPage>
  )
}
export default Register
