import React, { useState } from "react";
import { IonInput, IonToast } from "@ionic/react";
import url from "../../../axios.js";
import axios from "axios";
import contactIcon from "../../../images/icons/profile.svg";
import { useTranslation } from "react-i18next";

function ContactDetails(props) {
  const { t } = useTranslation();
  const [showToast1, setShowToast1] = useState(false);
  const [showToast2, setShowToast2] = useState(false);
  const [showToast2Text, setShowToast2Text] = useState(false);
  const [addressLine1, setAddressLine1] = useState(
    props.data.userData.address_line
  );
  const [addressLine2, setAddressLine2] = useState(
    props.data.userData.address_line2
  );
  const [city, setCity] = useState(props.data.userData.city);
  const [postcode, setPostcode] = useState(props.data.userData.postcode);
  const [stateRegion, setStateRegion] = useState(props.data.userData.state);
  const [mobile, setMobile] = useState(props.data.userData.phone);
  const [mobileCode, setMobileCode] = useState(
    props.data.userData.country_code
  );
  function save() {
    if (addressLine1 === undefined || addressLine1 === "") {
      setAddressLine1("");
      // } if (addressLine2 === undefined || addressLine2 === "") {
      //    setAddressLine2('');
    }
    if (city === undefined || city === "") {
      setCity("");
    }
    if (postcode === undefined || postcode === "") {
      setPostcode("");
    }
    if (stateRegion === undefined || stateRegion === "") {
      setStateRegion("");
    }
    if (mobile === undefined || mobile === "") {
      setMobile("");
    }
    if (mobileCode === undefined || mobileCode === "") {
      setMobileCode("");
    }
    if (
      addressLine1 !== undefined &&
      addressLine1 !== "" &&
      // addressLine2 !== undefined &&
      // addressLine2 !== '' &&
      city !== undefined &&
      city !== "" &&
      postcode !== undefined &&
      postcode !== "" &&
      stateRegion !== undefined &&
      stateRegion !== "" &&
      mobile !== undefined &&
      mobile !== "" &&
      mobileCode !== undefined &&
      mobileCode !== ""
    ) {
      props.setShowLoading(true);
      var data = {
        address_line: addressLine1,

        city: city,
        postcode: postcode,
        state: stateRegion,
        mobile: mobile,
        country_code: mobileCode
      };
      addressLine2 === ""
        ? console.log("No adress line 2")
        : (data.address_line2 = addressLine2);
      axios({
        method: "post",
        url: url + "/api/update-profile",
        headers: {
          Authorization: `Bearer ${props.data.token}`
        },
        data: data
      })
        .then(res => {
          if (res.data.status === 1) {
            props.updateUser(props.data.token);
            setShowToast1(true);
            props.setShowLoading(false);
          }
        })
        .catch(error => {
          /*422*/
          setShowToast2Text(error.response.data);
          setShowToast2(true);
          props.setShowLoading(false);
        });
    }
  }
  return (
    <div className="deposit-money">
      <div className="shade">
        <p className="top-shade">
          <img alt="contact details" src={contactIcon} />
        </p>
      </div>
      <p className="title">
        <span>{t('Account Details')}</span>
      </p>
      <p className="title-2">{t('Contact details')}</p>
      <div className="account-details-container">
        <div className="step-input-container">
          <p className="step-input-title">{t('Address Line')}</p>
          <IonInput
            className={
              "header-search " +
              (addressLine1 !== undefined && addressLine1 === "" ? "wrong" : "")
            }
            type="text"
            value={addressLine1}
            placeholder={t('Address Line')}
            onIonChange={e => setAddressLine1(e.detail.value)}
          />
        </div>
        <div className="step-input-container">
          <p className="step-input-title">{t('Address Line 2')}</p>
          <IonInput
            className={"header-search "}
            type="text"
            value={addressLine2}
            placeholder={t('Address Line 2')}
            onIonChange={e => setAddressLine2(e.detail.value)}
          />
        </div>
        <div className="step-input-container">
          <p className="step-input-title">{t('City')}</p>
          <IonInput
            className={
              "header-search " +
              (city !== undefined && city === "" ? "wrong" : "")
            }
            type="text"
            value={city}
            placeholder={t('City')}
            onIonChange={e => setCity(e.detail.value)}
          />
        </div>
        <div className="step-input-container">
          <p className="step-input-title">{t('Postcode')}</p>
          <IonInput
            className={
              "header-search " +
              (postcode !== undefined && postcode === "" ? "wrong" : "")
            }
            type="text"
            value={postcode}
            placeholder={t('Postcode')}
            onIonChange={e => setPostcode(e.detail.value)}
          />
        </div>
        <div className="step-input-container">
          <p className="step-input-title">{t('State/Region')}</p>
          <IonInput
            className={
              "header-search " +
              (stateRegion !== undefined && stateRegion === "" ? "wrong" : "")
            }
            type="text"
            value={stateRegion}
            placeholder={t('State/Region')}
            onIonChange={e => setStateRegion(e.detail.value)}
          />
        </div>
        <div className="step-input-container">
          <p className="step-input-title">{t('Mobile')}</p>
          <IonInput
            className={
              "header-search mobilecode " +
              (mobileCode !== undefined && mobileCode === "" ? "wrong" : "")
            }
            type="tel"
            value={mobileCode}
            placeholder={t('Country code')}
            onIonChange={e => setMobileCode(e.detail.value)}
          />
          <IonInput
            className={
              "header-search mobile " +
              (mobile !== undefined && mobile === "" ? "wrong" : "")
            }
            type="tel"
            value={mobile}
            placeholder={t('Mobile')}
            onIonChange={e => setMobile(e.detail.value)}
          />
        </div>
        <div className="lower">
          <p onClick={() => save()} className="next-step unselectable">
            {t('Save')}
          </p>
        </div>
      </div>
      <IonToast
        isOpen={showToast1}
        onDidDismiss={() => setShowToast1(false)}
        message={t('Your data has been successfully changed.')}
        color="primary"
        duration={10000}
      />
      <IonToast
        isOpen={showToast2}
        onDidDismiss={() => setShowToast2(false)}
        message={showToast2Text}
        color="primary"
        duration={10000}
      />
    </div>
  );
}

export default ContactDetails;
