import {
  IonContent,
  IonIcon,
  IonItem,
  IonLabel,
  IonToggle,
  IonList,
  IonMenu,
  IonMenuToggle,
  IonModal,
  IonNote
} from "@ionic/react";
import React, { useState } from "react";
import setDarkTheme from "../sbook.js";
import { useLocation, useHistory } from "react-router-dom";
import {
  analyticsOutline,
  cashOutline,
  trendingUpOutline,
  trendingDownOutline,
  keyOutline,
  calendarOutline,
  calendarClearOutline,
  manOutline,
  newspaperOutline,
  peopleOutline,
  gameControllerOutline,
  checkmarkCircleOutline,
  closeCircleOutline
 } from "ionicons/icons";
import "./Menu.css";
import axios from "axios";
import url from "../axios.js";
import { useTranslation } from "react-i18next";

interface AppPage {
  url: string;
  iosIcon: string;
  mdIcon: string;
  title: string;
}

const appPages: AppPage[] = [
  {
    title: "Statistics",
    url: "/user/statistics",
    iosIcon: analyticsOutline,
    mdIcon: analyticsOutline
  },
  {
    title: "Deposit money",
    url: "/user/deposit-money",
    iosIcon: trendingUpOutline,
    mdIcon: trendingUpOutline
  },
  {
    title: "Withdraw money",
    url: "/user/withdraw-money",
    iosIcon: trendingDownOutline,
    mdIcon: trendingDownOutline
  },
  {
    title: "Balance",
    url: "/user/balance",
    iosIcon: cashOutline,
    mdIcon: cashOutline
  },
  {
    title: "Funds security",
    url: "/user/funds-security",
    iosIcon: keyOutline,
    mdIcon: keyOutline
  },
  {
    title: "Transactions history",
    url: "/user/transactions",
    iosIcon: calendarOutline,
    mdIcon: calendarOutline
  },
  {
    title: "Bets history",
    url: "/user/casino-and-games",
    iosIcon: calendarClearOutline,
    mdIcon: calendarClearOutline
  },
  {
    title: "Personal details",
    url: "/user/personal-details",
    iosIcon: manOutline,
    mdIcon: manOutline
  },
  {
    title: "Contact details",
    url: "/user/contact-details",
    iosIcon: newspaperOutline,
    mdIcon: newspaperOutline
  },
  {
    title: "Games limit",
    url: "/user/games-limit",
    iosIcon: gameControllerOutline,
    mdIcon: gameControllerOutline
  },
  {
    title: "Multiple logins",
    url: "/user/multiple-logins",
    iosIcon: peopleOutline,
    mdIcon: peopleOutline
  },
  {
    title: "Verify account",
    url: "/user/verify-account",
    iosIcon: checkmarkCircleOutline,
    mdIcon: checkmarkCircleOutline
  },
  {
    title: "Close account",
    url: "/user/close-account",
    iosIcon: closeCircleOutline,
    mdIcon: closeCircleOutline
  }
];

const Menu: React.FC = (props: any) => {
  const location = useLocation();
  // const history = useHistory();
  const { t } = useTranslation();
  const tmp = localStorage.getItem("theme");
  const [theme, setTheme] = useState(tmp==='true'? true: false);
  const [showPopover, setShowPopover] = useState(false);
  function toggle() {
    let stheme = localStorage.getItem("theme");
    if (stheme ==='false' || stheme === null) {
      localStorage.setItem("theme", 'true');
      setTheme(true)
      setDarkTheme(true);
    } if (stheme ==='true') {
      localStorage.setItem("theme", 'false');
      setTheme(false)
      setDarkTheme(false);
    }
    document.getElementsByTagName('body')[0].classList.toggle("dark")

  }
  function logout() {
    props.setShowLoading(true);
    axios({
      method: "post",
      url: url + "/api/logout",
      headers: {
        Authorization: `Bearer ${props.data.token}`
      }
    }).then(res => {
      setShowPopover(false);
      localStorage.removeItem("token");
      props.setShowLoading(false);
      document.location.reload();
      document.getElementsByTagName("body")[0].classList.remove("hide-chat");
    });
  }
  return (
    <IonMenu side="end" contentId="main" type="overlay">
      <IonContent>
        <IonList id="inbox-list">
          {props.data.token !== null && (
            <div className="login-buttons lg">
              <div className="login-register-container logged">
                <div
                  className="userpic"
                  style={
                    props.data.userData.avatar === null
                      ? { backgroundImage: `url(${props.data.defaultAvatar})` }
                      : {
                          backgroundImage: `url(${props.data.userData.avatar})`
                        }
                  }
                />
                <p className="name">
                  {props.data.userData.first_name +
                    " " +
                    props.data.userData.last_name || "guest"}
                </p>
                <div className="toggler">
                  <IonToggle
                    checked={theme}
                    onIonChange={() => toggle()}
                    className={'themeToggle'}
                    ion-toggle-text
                    ng-model="simple">
                  </IonToggle>

                  </div>
              </div>

              <IonNote>{t('Balance')}: {props.data.balance[0].ob_priv}€</IonNote>
            </div>
          )}

        </IonList>
        {/*<IonListHeader>Inbox</I onListHeader>
          <IonNote>hi@ionicframework.com</IonNote>*/}
        {appPages.map((appPage, index) => {
          return (
            <IonMenuToggle key={index} autoHide={false}>
              <IonItem
                className={location.pathname === appPage.url ? "selected" : ""}
                routerLink={appPage.url}
                routerDirection="none"
                lines="none"
                detail={false}
              >
                <IonIcon
                  slot="start"
                  ios={appPage.iosIcon}
                  md={appPage.mdIcon}
                />
                <IonLabel>{t(appPage.title)}</IonLabel>
              </IonItem>
            </IonMenuToggle>
          );
        })}
        <p
          className="logout logout-btn"
          onClick={() => {
            setShowPopover(true);
            document.getElementsByTagName("body")[0].classList.add("hide-chat");
          }}
        >
          {t('Logout')}
        </p>
      </IonContent>
      <IonModal
        isOpen={showPopover}
        cssClass="login-modal logout logreg"
        onDidDismiss={() => setShowPopover(false)}
      >
        <div className="modal-container">
          <div className="modal-close" onClick={() => setShowPopover(false)} />
          <div className="cards" />
          <div className="modal-container-title">{t('Logout')}</div>
          <p className="logout-p">{t('Are you sure you want to log out?')}</p>
          <div onClick={() => logout()} className="login-btn">
            {t('Logout')}
          </div>
        </div>
      </IonModal>
    </IonMenu>
  );
};

export default Menu;
