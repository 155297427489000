import React from 'react';
import { memo } from "react";
import { Helmet } from "react-helmet";
import {
  useIonViewDidEnter,
} from "@ionic/react";
const Frame = memo(props => {

  useIonViewDidEnter(() => {
 });
    return (
      <div className='frame-div'>
        <div onScroll={() => console.log(11)} id='egamings_container' />
        { props.htmlString !== '' && props.token === null &&
          <Helmet>
              <script type="text/javascript">
                {/<script\b[^>]*>([\s\S]*?)<\/script>/gm.exec(props.htmlString)[1]}
              </script>
          </Helmet>
          }
          { props.htmlString !== '' && props.token !== null &&
            <Helmet>
                <script type="text/javascript">
                  {/<script\b[^>]*>([\s\S]*?)<\/script>/gm.exec(props.htmlString)[1]}
                </script>
            </Helmet>
            }
      </div>
    )
  }
)

export default Frame
