import { IonContent, IonPage } from "@ionic/react";
import React, { useState } from "react";
import UsersByDevice from "../../components/MainChart.js";
import Header from "../../components/Header.js";
import Footer from "../../components/Footer.js";
import UserSpinner from "../../components/UserLoading.js";
import DepositMoney from "./bank/DepositMoney.js";
import FundsSecurity from "./bank/FundsSecurity.js";
import TransferMoney from "./bank/TransferMoney.js";
import Balance from "./bank/Balance.js";
import Loan from "./bank/Loan.js";
import WithdrawMoney from "./bank/WithdrawMoney.js";
import Transactions from "./history/Transactions.js";
import CasinoGames from "./history/CasinoGames.js";
import PersonalDetails from "./account/PersonalDetails.js";
import GamesLimit from "./account/GamesLimit.js";
import ContactDetails from "./account/ContactDetails.js";
import MultipleLogins from "./account/MultipleLogins.js";
import CloseAccount from "./account/CloseAccount.js";
import VerifyAccount from "./account/VerifyAccount.js";
import Terms from "./help/Terms.js";
import "../../css/Main-1.css";
import "../../css/register/Register.css";
import "../../css/Media.css";
import "../../css/UserCabinet.css";
import { useLocation } from "react-router-dom";
import MenuUser from "../../components/MenuUser.js";
import { Redirect } from "react-router-dom";
import statistics from "../../images/icons/trend.svg";
import { useTranslation } from "react-i18next";
function User(props) {
  let location = useLocation();
  const { t } = useTranslation();
  const [showLoading, setShowLoading] = useState(false);
  return (
    <IonPage>
      {props.data.token === null && <Redirect to="/home" />}
      <IonContent overflow-scroll="true" class="has-header">
        <Header
          theme={props.theme}
          setTheme={props.setTheme}
          getInfo={props.getInfo}
          toggle={props.toggle}
          clearState={props.clearState}
          setShowLoading={props.setShowLoading}
          setToken={props.setToken}
          page={"Профиль"}
          data={props.data}
          changeAppLang={props.changeAppLang}
          toggleMode={props.toggleMode}
        />
        <div className="user-container app-centered">
          <MenuUser />
          <div className="user-cabinet-container">
            {location.pathname !== "/user/multiple-logins" && (
              <UserSpinner showLoading={showLoading} />
            )}
            {location.pathname === "/user/deposit-money" && (
              <DepositMoney
                updateUser={props.updateUser}
                setShowLoading={setShowLoading}
                data={props.data}
              />
            )}
            {location.pathname === "/user/funds-security" && <FundsSecurity />}
            {location.pathname === "/user/transfer-money" && (
              <TransferMoney
                updateUser={props.updateUser}
                setShowLoading={setShowLoading}
                data={props.data}
              />
            )}
            {location.pathname === "/user/withdraw-money" && (
              <WithdrawMoney
                updateUser={props.updateUser}
                setShowLoading={setShowLoading}
                data={props.data}
              />
            )}
            {location.pathname === "/user/balance" && (
              <Balance
                updateUser={props.updateUser}
                setShowLoading={setShowLoading}
                data={props.data}
              />
            )}
            {location.pathname === "/user/loan" && (
              <Loan
                updateUser={props.updateUser}
                setShowLoading={setShowLoading}
                data={props.data}
              />
            )}
            {location.pathname === "/user/transactions" && (
              <Transactions data={props.data} setShowLoading={setShowLoading} />
            )}
            {location.pathname === "/user/casino-and-games" && (
              <CasinoGames data={props.data} setShowLoading={setShowLoading} />
            )}
            {location.pathname === "/user/personal-details" && (
              <PersonalDetails
                updateUser={props.updateUser}
                setShowLoading={setShowLoading}
                data={props.data}
              />
            )}
            {location.pathname === "/user/contact-details" && (
              <ContactDetails
                updateUser={props.updateUser}
                setShowLoading={setShowLoading}
                data={props.data}
              />
            )}
            {location.pathname === "/user/games-limit" && (
              <GamesLimit
                updateUser={props.updateUser}
                setShowLoading={setShowLoading}
                data={props.data}
              />
            )}
            {location.pathname === "/user/multiple-logins" && (
              <MultipleLogins
                logins={props.logins}
                setShowLoading={setShowLoading}
                data={props.data}
              />
            )}
            {location.pathname === "/user/close-account" && (
              <CloseAccount
                clearState={props.clearState}
                setShowLoading={setShowLoading}
                data={props.data}
              />
            )}
            {location.pathname === "/user/verify-account" && (
              <VerifyAccount
                setShowLoading={setShowLoading}
                data={props.data}
              />
            )}
            {location.pathname === "/user/terms-and-conditions" && <Terms />}
            {/*location.pathname*/}
            {location.pathname === "/user/statistics" && (
              <div className="deposit-money">
                <div className="shade">
                  <p className="top-shade">
                    <img alt="statistics" src={statistics} />
                  </p>
                </div>
                <p className="title">
                  <span>{t('Statistics')}</span>
                </p>
                <div className="charts-container">
                  <UsersByDevice
                    setShowLoading={setShowLoading}
                    data={props.data}
                  />
                </div>
              </div>
            )}
          </div>
        </div>
        {location.pathname.includes('/user') && <Footer />}
      </IonContent>
    </IonPage>
  );
}

export default User;
