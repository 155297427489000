import React from "react";
import "../css/UserMenu.css";
import { Link } from "react-router-dom";
import deposit from "../images/icons/deposit2.svg";
import { ReactComponent as Withdraw } from "../images/icons/withdraw.svg";
import balance from "../images/icons/balance.svg";
import loan from "../images/icons/loan.svg";
import cookie from "../images/icons/cookie.svg";
import privacy from "../images/icons/privacy.svg";
import kyc from "../images/icons/kyc.svg";
import contributions from "../images/icons/contributions.svg";
import disclaimer from "../images/icons/disclaimer.svg";
import responsible from "../images/icons/policy.svg";
import dataPolicy from "../images/icons/data-policy.svg";
import fundsSecurity from "../images/icons/funds-security.svg";
import transactions from "../images/icons/transaction2.svg";
import { ReactComponent as Casino } from "../images/icons/casino.svg";
import { ReactComponent as User } from "../images/icons/user.svg";
import statistics from "../images/icons/trend.svg";
import { ReactComponent as Contact } from "../images/icons/profile.svg";
import { ReactComponent as Limit } from "../images/icons/limit.svg";
import { ReactComponent as Multiple } from "../images/icons/team.svg";
import { ReactComponent as Verify } from "../images/icons/check.svg";
import { ReactComponent as Close } from "../images/icons/close.svg";
import { ReactComponent as Help } from "../images/icons/question.svg";
import { ReactComponent as Terms } from "../images/icons/terms.svg";
import { useTranslation } from "react-i18next";
function MenuUser(props) {
  const { t } = useTranslation();
  return (
    <div id="user-menu">
      <div className="user-menu-container">
        <p className="user-menu-title">{t('Statistics')}</p>
        <p className="with-link">
          <img alt="statistics" src={statistics} />
          <Link className="user-menu-link" to={"/user/statistics"}>
            {t('Overall statistics')}
          </Link>
        </p>
      </div>
      <div className="user-menu-container">
        <p className="user-menu-title">{t('Balance')}</p>
        <p className="with-link">
          <img alt="deposit" src={deposit} />
          <Link className="user-menu-link" to={"/user/deposit-money"}>
            {t('Deposit money')}
          </Link>
        </p>
        {
          // <p className="with-link">
          //   <Exchange />
          //   <Link className="user-menu-link" to={"/user/transfer-money"}>
          //     Exchange Money
          //   </Link>
          // </p>
        }
        <p className="with-link">
          <Withdraw />
          <Link className="user-menu-link" to={"/user/withdraw-money"}>
            {t('Withdraw money')}
          </Link>
        </p>
        <p className="with-link">
          <img alt="balance" src={balance} />
          <Link className="user-menu-link" to={"/user/balance"}>
          {t('Balance')}
          </Link>
        </p>
        <p className="with-link">
          <img alt="fundsSecurity" src={loan} />
          <Link className="user-menu-link" to={"/user/loan"}>
            {t('Loan request')}
          </Link>
        </p>
        <p className="with-link">
          <img alt="fundsSecurity" src={fundsSecurity} />
          <Link className="user-menu-link" to={"/user/funds-security"}>
            {t('Funds security')}
          </Link>
        </p>
      </div>
      <div className="user-menu-container">
        <p className="user-menu-title">{t('History')}</p>
        <p className="with-link">
          <img alt="transactions" src={transactions} />
          <Link className="user-menu-link" to={"/user/transactions"}>
            {t('Transactions')}
          </Link>
        </p>
        <p className="with-link">
          <Casino />
          <Link className="user-menu-link" to={"/user/casino-and-games"}>
            {t('Bets history')}
          </Link>
        </p>
      </div>
      <div className="user-menu-container">
        <p className="user-menu-title">{t('Account Details')}</p>
        <p className="with-link">
          <User />
          <Link className="user-menu-link" to={"/user/personal-details"}>
            {t('Personal details')}
          </Link>
        </p>
        <p className="with-link">
          <Contact />
          <Link className="user-menu-link" to={"/user/contact-details"}>
            {t('Contact details')}
          </Link>
        </p>
        <p className="with-link">
          <Limit />
          <Link className="user-menu-link" to={"/user/games-limit"}>
            {t('Games limit')}
          </Link>
        </p>
        <p className="with-link">
          <Multiple />
          <Link className="user-menu-link" to={"/user/multiple-logins"}>
            {t('Multiple logins')}
          </Link>
        </p>
        <p className="with-link">
          <Verify />
          <Link className="user-menu-link" to={"/user/verify-account"}>
            {t('Verify account')}
          </Link>
        </p>
        <p className="with-link">
          <Close />
          <Link className="user-menu-link" to={"/user/close-account"}>
            {t('Close account')}
          </Link>
        </p>
      </div>
      <div className="user-menu-container">
        <p className="user-menu-title">{t('Help & Info')}</p>
        {
        //   <p className="with-link">
        //   <Help />
        //   <Link className="user-menu-link" to={"/documents/help"}>
        //     {t('Help')}
        //   </Link>
        // </p>
      }
        <p className="with-link">
          <Terms />
          <Link className="user-menu-link" to={"/documents/terms"}>
            {t('Terms and conditions')}
          </Link>
        </p>
        <p className="with-link">
          <img alt="balance" src={cookie} />
          <Link className="user-menu-link" to={"/documents/cookie-policy"}>
          {t('Cookie policy')}
          </Link>
        </p>
        <p className="with-link">
          <img alt="balance" src={privacy} />
          <Link className="user-menu-link" to={"/documents/policy"}>
            {t('Privacy policy')}
          </Link>
        </p>
        <p className="with-link">
          <img alt="balance" src={kyc} />
          <Link className="user-menu-link" to={"/documents/kyc-policy"}>
          {t('KYC Policy')}
          </Link>
        </p>
        <p className="with-link">
          <img alt="balance" src={contributions} />
          <Link className="user-menu-link" to={"/documents/disclaimer"}>
          {t('Disclaimer')}
          </Link>
        </p>
        <p className="with-link">
          <img alt="balance" src={disclaimer} />
          <Link className="user-menu-link" to={"/documents/contributions"}>
          {t('Betting terms')}
          </Link>
        </p>
        <p className="with-link">
          <img alt="balance" src={responsible} />
          <Link className="user-menu-link" to={"/documents/responsible-policy"}>
          {t('Responible gambling policy')}
          </Link>
        </p>
        <p className="with-link">
          <img alt="balance" src={dataPolicy} />
          <Link className="user-menu-link" to={"/documents/data-protection"}>
          {t('Data protection policy')}
          </Link>
        </p>
      </div>
    </div>
  );
}

export default MenuUser;
