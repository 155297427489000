import React, { useState } from "react";
import "../css/header1.css";
// import "../css/Header.css";
import { IonButton,IonSelect,IonToggle, IonSelectOption,IonModal, IonMenuButton, IonButtons } from "@ionic/react";
import { Link } from "react-router-dom";
import LoginModal from "./LoginModal.js";
import { useLocation, useHistory } from "react-router-dom";
import axios from "axios";
import url from "../axios.js";
import { useTranslation } from "react-i18next";
import setDarkTheme from "../sbook.js";

const Header = props => {
  const { t } = useTranslation();
  const location = useLocation();
  const history = useHistory();
  const [lang, setLang] = useState(localStorage.getItem("Lang"));
  const [showLoginModal, setShowLoginModal] = useState(false);
  const [showPopover, setShowPopover] = useState(false);
  function logout() {
    props.setShowLoading(true);
    axios({
      method: "post",
      url: url + "/api/logout",
      headers: {
        Authorization: `Bearer ${props.data.token}`
      }
    }).then(res => {
      setShowPopover(false);
      localStorage.removeItem("token");
      props.setShowLoading(false);
      document.location.reload();
      document.getElementsByTagName("body")[0].classList.remove("hide-chat");
    });
  }
  function changeLang(e) {
    setLang(e);
    localStorage.setItem("Lang", e);
    document.location.reload();
  }
  function toggle() {
    if (props.theme === false || props.theme === null) {
      localStorage.setItem("theme", true);
      props.setTheme(true)
      document.getElementsByTagName('body')[0].classList.add("dark");
      setDarkTheme(true);
    }
    if (props.theme === true || props.theme === 'true') {
      localStorage.setItem("theme", false);
      props.setTheme(false)
      document.getElementsByTagName('body')[0].classList.remove("dark");
      setDarkTheme(false);
    }


  }
  return (
    <div className="header-main">
      <a className="logo-link" href={"/home"}><div className="logo"></div></a>
      <div className="links-container">
        <a
          className={location.pathname === "/home" ? "menu-active" : ""}
          href={"/home"}
        >
          {t("Home")}
        </a>
        <a
          rel="noopener noreferrer"
          target="_blank"
          href="https://bingo.bet/home"
        >
          {t("Casino")}
        </a>
        <a
          rel="noopener noreferrer"
          target="_blank"
          href="https://bingo.bet/games/live"
        >
          Live
        </a>
        <p className='toggle-languages'>
          <IonSelect onIonChange={e => changeLang(e.detail.value)} className='popover-lang' value={lang} interface="popover">
            <IonSelectOption value="GB">English</IonSelectOption>
            <IonSelectOption value="ES">Español</IonSelectOption>
            <IonSelectOption value="IT">Italiano</IonSelectOption>
            <IonSelectOption value="DE">Deutsche</IonSelectOption>
            <IonSelectOption value="NL">Nederlands</IonSelectOption>
            <IonSelectOption value="PL">Polski</IonSelectOption>
            <IonSelectOption value="PT">Português</IonSelectOption>
            <IonSelectOption value="RU">Русский</IonSelectOption>
            <IonSelectOption value="TR">Türk</IonSelectOption>
            <IonSelectOption value="FR">Français</IonSelectOption>
            <IonSelectOption value="CN">中文</IonSelectOption>
            <IonSelectOption value="JP">日本人</IonSelectOption>
          </IonSelect>
        </p>

      </div>
      {props.data.token === null && (
        <div className="login-buttons desk-only-btns">
          {location.pathname === '/home' && <div className="toggler">
            <IonToggle
              checked={props.theme}
              onIonChange={() => toggle()}
              className={'themeToggle'}
              ion-toggle-text
              ng-model="simple">
            </IonToggle>

            </div>}
          <IonButton
            mode="md"
            onClick={() => setShowLoginModal(true)}
            className="login-btn"
          >
            {t("Login")}
          </IonButton>
          <IonButton
            mode="md"
            onClick={() => history.push("/register")}
            className="register-btn"
          >
            {t("Register")}
          </IonButton>
        </div>
      )}
      {props.data.token !== null && (
        <IonButtons className="menu-button-ionic" slot="end">
          <IonMenuButton />
        </IonButtons>
      )}
      {props.data.token !== null && (
        <div className="login-buttons lg">
          <div className="login-register-container logged">
            <div
              onClick={() => history.push("/user/statistics")}
              className="userpic"
              style={
                props.data.userData.avatar === null
                  ? { backgroundImage: `url(${props.data.defaultAvatar})` }
                  : { backgroundImage: `url(${props.data.userData.avatar})` }
              }
            />
              <Link to={"/user/statistics"} className="name">
                {props.data.userData.first_name +
                  " " +
                  props.data.userData.last_name || "guest"}
              </Link>
            <p
              className="logout logout-btn"
              onClick={() => {
                setShowPopover(true);
                document
                  .getElementsByTagName("body")[0]
                  .classList.add("hide-chat");
              }}
            >
              {t("Logout")}
            </p>
          </div>
          <div className="toggler">
            <Link to={"/user/balance"} className="name balance-value">
              <p>{t("Balance")}: </p> <p className="balance-colored">{' '}{props.data.balance.length > 0 ? props.data.balance[0].ob_priv : 0 }€</p>
            </Link>
          <IonToggle
              checked={props.theme}
              onIonChange={() => toggle()}
              className={'themeToggle'}
              ion-toggle-text
              ng-model="simple">
            </IonToggle>

            </div>




          </div>
      )}
      <LoginModal
        setShowLoading={props.setShowLoading}
        setToken={props.setToken}
        showLoginModal={showLoginModal}
        setShowLoginModal={setShowLoginModal}
      />
      <IonModal
        isOpen={showPopover}
        cssClass="login-modal logout logreg"
        onDidDismiss={() => setShowPopover(false)}
      >
        <div className="modal-container">
          <div className="cards" />
          <div className="modal-container-title">{t("Logout")}</div>
          <p className="logout-p">{t("Are you sure you want to log out?")}</p>
          <div onClick={() => logout()} className="login-btn">
            {t("Logout")}
          </div>
        </div>
      </IonModal>
    </div>
  );
};

export default Header;
