import React,{useEffect,useState} from 'react';
import { useAtom } from "jotai";
import { Redirect,useLocation } from 'react-router-dom';
import { promo } from "../state.js";

const Promo = (props) => {
  let location = useLocation();
  /*eslint-disable*/
  const [move, setMove] = useState(false);
  const [code, setCode] = useAtom(promo);
  useEffect(()=> {
    if ( location.search !== '') {
      setCode(location.search.slice(2));
      setMove(true);
    }
  },[location.search]);
  return (
    <>
    {move === true &&
    <Redirect to="/register" />}
    </>
  )
}

export default Promo
