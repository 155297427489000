import React,{useEffect} from 'react';
import '../css/footer.css';
import { Link, useLocation } from "react-router-dom";
import { ReactComponent as Instagram } from '../images/logos/insta.svg';
import { ReactComponent as Facebook } from '../images/logos/fb.svg';
import { ReactComponent as Twitter } from '../images/logos/twi.svg';
import { ReactComponent as Telegram } from '../images/logos/tg.svg';
import { ReactComponent as Ridotto } from '../images/logos/logo-fullsize.svg';
import { useTranslation } from "react-i18next";


const Footer = (props) => {
  const { t } = useTranslation();
  const location = useLocation();
  useEffect(()=> {
      window.apg_e2d99ef0_80aa_4b65_965a_b09eccf29ba2.init();
  },[location.pathname])
  return (

    <div name="firstInsideContainer" className='footer-container'>
      {
        // <div className="footer-container">
        //   <p>Bingo.bet is a brand managed by Bingo.bet B.V.(Curacao) & Bingo.bet Ltd (Isle of Man). Bingo.bet B.V. is incorporated on Curacao at registered address Chuchubiweg 17, Willemstad, Curacao. Bingo.bet B.V. is registered in accordance with Curacao law with registration number 154986. Bingo.bet Ltd is incorporated on Isle of Man at registered address 49 Victoria Street, Douglas, IM1 2LD, Isle of Man. Bingo.bet Ltd is registered in accordance with Isle of Man law with registration number 018297V. Gambling can be addictive. Play responsibly. For further information on the risks associated with gambling, read our Responible gambling policy. Underage gambling is an offence.</p>
        //   <div className="links">
        //     <a rel="noopener noreferrer" target="_blank" className='link-left' href="https://services.gov.im/ded/services/companiesregistry/viewcompany.iom?Id=511685#agents"><div></div></a>
        //     <a rel="noopener noreferrer" target="_blank" className='link-right' href="http://www.curacao-chamber.cw/services/registry/search-company"><div></div></a>
        //   </div>
        // </div>
      }

      <div className='links-top'>
        <div className="visa"></div>
        <div className="mastercard"></div>
        <div className="maestro"></div>
        <div className="paypal"></div>
        <div className="neteller"></div>
        <div className="sofort"></div>
        <div className="paysafe"></div>
        <div className="zimpler"></div>
        <div className="jeton"></div>
        <div className="trustly"></div>
        <div className="skrill"></div>
      </div>
      <div className='socials'>
        <a href="https://www.instagram.com/bingo.uk/" target="_blank" rel="noopener noreferrer">
          <div className='instagram'>
            <Instagram />
          </div>
        </a>
        <a href="/" target="_blank" rel="noopener noreferrer">
          <div className='facebook'>
            <Facebook />
          </div>
        </a>
        <a href="https://twitter.com/bingobet2" target="_blank" rel="noopener noreferrer">
          <div className='twitter'>
            <Twitter />
          </div>
        </a>
        <a href="https://t.me/bb_casino" target="_blank" rel="noopener noreferrer">
          <div className='telegram'>
            <Telegram />
          </div>
        </a>
      </div>
      <div className='licensing'>
        <h3>{t("licensing and regulation")}</h3>
        <p>{t("Bingo.bet is a brand managed by Bingo.bet B.V.(Curacao) & Bingo.bet Ltd (Isle of Man).")}</p>
        <p>{t("Bingo.bet B.V. is incorporated on Curacao at registered address Chuchubiweg 17, Willemstad, Curacao. Bingo.bet B.V. is registered in accordance with Curacao law with registration number 154986.")}</p>
        <p>{t("Bingo.bet Ltd  is incorporated on Isle of Man at registered address 49 Victoria Street, Douglas, IM1 2LD, Isle of Man. Bingo.bet Ltd is registered in accordance with Isle of Man law with registration number 018297V.")}</p>
        <p>{t("Gambling can be addictive. Play responsibly. For further information on the risks associated with gambling, read our")} <Link to="/documents/responsible-policy">{t("Responible gambling policy")}</Link>. {t("Underage gambling is an offence.")}</p>
        <div className = "to-pic">
          <a className='link-to-gov' href="https://services.gov.im/ded/services/companiesregistry/viewcompany.iom?Id=511685#agents" target="_blank" rel="noopener noreferrer">
            <div className='to-gov'></div>
          </a>
          <a className='link-to-kkk' href="http://www.curacao-chamber.cw/services/registry/search-company" target="_blank" rel="noopener noreferrer">
            <div className='to-kkk'></div>
          </a>
          <div className='footer-valid'>
            <div id="apg-e2d99ef0-80aa-4b65-965a-b09eccf29ba2" data-apg-seal-id="e2d99ef0-80aa-4b65-965a-b09eccf29ba2" data-apg-image-size="128" data-apg-image-type="basic-small"></div>
          </div>
        </div>
      </div>
      {/*<div className='links-bottom'>
        <div className='eighteen'></div>
        <div className='mga'></div>
        <div className='gambling'></div>
        <div className='genesis'></div>
        <div className='begambling'></div>
        <div className='stodlingen'></div>
        <div className='responsible'></div>
      </div>*/}
      <div className='bottom'>

        <div className="container">
          <div>
            <Link to="/documents/disclaimer">{t("Disclaimer")}</Link>
            <Link to="/documents/cookie-policy">{t("Cookie policy")}</Link>
            <Link to="/documents/policy">{t("Privacy policy")}</Link>
            <Link to="/documents/kyc-policy">{t("KYC Policy")}</Link>
          </div>
          <div>
            <Link to="/documents/contributions">{t("Betting terms")}</Link>
            <Link to="/documents/anti-money-laundering">{t("Anti-money laundering policy")}</Link>
            <Link to="/documents/terms">{t("Terms and conditions")}</Link>
            <Link to="/documents/responsible-policy">{t("Responible gambling policy")}</Link>
            <Link to="/documents/data-protection">{t("Data protection policy")}</Link>
          </div>

        </div>
        <div className='imgs'>
          <div className='eighteen'></div>
          <a href="https://im.casino/" target="_blank" rel="noopener noreferrer" className="logo-im">
            <div className='im-casino'></div>
          </a>
        </div>
        <a href="https://ridotto.biz" target="_blank" rel="noopener noreferrer" className="logo-ridotto">
          <div>
            <Ridotto/>
          </div>
        </a>

      </div>
    </div>
  )
}

export default Footer
