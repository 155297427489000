import React,{useState} from 'react';
import { IonButton, IonModal, IonInput, IonToast } from "@ionic/react";
import { useTranslation } from "react-i18next";
import axios from 'axios';
import url from '../axios.js';

const RestoreModal = (props) => {
  const { t } = useTranslation();
  const [username, setUsername] = useState('');
  const [stage, setStage] = useState(1);
  const [loginError, setLoginError] = useState(false);
  const [loginErrorMessage, setLoginErrorMessage] = useState(false);
  const [vcode, setVcode] = useState('');
  const [pass, setPass] = useState('');
  const [rpass, setRpass] = useState('');
  function restorePassword() {
    if (username !== '') {
      axios({
        method: 'post',
        url: url + "/api/restore-password",
        data: {
          restore: username
        }
      })
        .then(res => {
          if (res.data.status === 1) {
            setStage(2);
          }
          else {
            setLoginErrorMessage(t(res.data.error));
            setLoginError(true);
          }
        }
        )
    }
  }
  function restoreFinal() {
    if (pass === rpass) {
      axios({
        method: 'post',
        url: url + "/api/restore-confirm",
        data: {
          password: pass,
          confirmation: vcode,
          restore: username
        }
      })
        .then(res => {
          if (res.data.status === 1) {
            setLoginErrorMessage("Your password succesfully changed");
            setLoginError(true);
            function close() {
              props.closeAll();
              props.setOpen(false);
            }

            setTimeout(close, 5000);

          }
          else {
            setLoginErrorMessage(t(res.data.errors));
            setLoginError(true);
          }
        })
    }
  }
  return (
      <IonModal
        mode={"md"}
        onDidDismiss={() => {props.closeAll();props.setOpen(false)}}
        isOpen={props.isOpen}
        cssClass="login-modal"
      >
        <div className="modal-container">
          <div
            className="modal-close"
            onClick={() => {props.closeAll();props.setOpen(false)}}
          />
          <div className="big-logo" />
          {stage === 1 &&
            <>
            <p className={'rest-p'}>{t("Enter your email, phone, or username and we'll send you a link to get back into your account")}</p>
            <IonInput
              value={username}
              onIonChange={e => setUsername(e.detail.value)}
              placeholder={'mail@email.com'}
              className="modal-input"
            />
            <IonButton onClick={() => restorePassword()} className="modal-button">
              {t("Send")}
            </IonButton>
          </>
        }
        {
          stage === 2 &&
          <>
            <IonInput
              value={username}
              onIonChange={e => setUsername(e.detail.value)}
              placeholder={'mail@email.com'}
              className="modal-input"
            />
            <IonInput
              value={vcode}
              onIonChange={e => setVcode(e.detail.value)}
              placeholder={t("Verification code from e-mail")}
              className="modal-input"
            />
            <IonInput
              value={pass}
              onIonChange={e => setPass(e.detail.value)}
              placeholder={t("New password")}
              className="modal-input"
            />
            <IonInput
              value={rpass}
              onIonChange={e => setRpass(e.detail.value)}
              placeholder={t("Repeat new password")}
              className="modal-input"
            />
            <IonButton onClick={() => restoreFinal()} className="modal-button">
              {t("Change password")}
            </IonButton>
          </>
        }
          {/*<IonInput
            value={password}
            onIonChange={e => setPassword(e.detail.value)}
            placeholder={t("Password")}
            type="password"
            className="modal-input"
          />
          <IonButton onClick={() => login()} className="modal-button">
            {t("Login")}
          </IonButton>
          */}
        </div>
        <IonToast
          isOpen={loginError}
          onDidDismiss={() => {
            setLoginError(false);
          }}
          message={loginErrorMessage}
          color="danger"
          duration={5000}
        />
      </IonModal>
  )
}

export default RestoreModal
